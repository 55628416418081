// import moment from "moment";
import dayjs from 'dayjs';

export const myMoment = (date, format) => {
  let mm;
  // if (date) {
  //     mm = moment(date);
  // } else {
  //     mm = moment();
  // }
  try {
    if (format) {
      //     mm = moment(date, format);
      mm = dayjs(date, format);
    } else {
      //     mm = moment(dayjs(date));
      mm = dayjs(date);
    }
  } catch (error) {
    // mm = moment(dayjs());
    mm = dayjs();
  }

  return mm;
};

const UtilDate = {
  formatDateTime: (dat) => {
    if (dat) {
      try {
        return myMoment(dat).format('DD/MM/YYYY HH:mm');
      } catch (error) {
        return '---';
      }
    }
    return '---';
  },
  formatMonthYear: (dat) => {
    if (dat) {
      try {
        return myMoment(dat).format('MMM YYYY');
      } catch (error) {
        return '---';
      }
    }
    return '---';
  },
  formatDate: (dat) => {
    if (dat) {
      try {
        return myMoment(dat).format('DD/MM/YYYY');
      } catch (error) {
        return '---';
      }
    }
    return '---';
  },
  getDateToday: () => {
    return myMoment();
  },
  getThisMonth: () => {
    return myMoment().month();
  },
  getThisYear: () => {
    return myMoment().year();
  },
  getDate: (dat) => {
    // return dayjs(dat)
    return myMoment(dat);
  },
  getDateYesterday: (today) => {
    return myMoment(today).subtract(1, 'day');
  },
  getDateTomorrow: (today) => {
    return myMoment(today).add(1, 'day');
  },
  getDateNextMonth: (today) => {
    return myMoment(today).add(1, 'month');
  },
  getDatePrevMonth: (today) => {
    return myMoment(today).subtract(1, 'month');
  },
  getDateDayCount: (dayCount = 1) => {
    return myMoment().add(dayCount, 'day').day();
  },
  getDiffDaysCount: (dat) => {
    return myMoment().diff(dat, 'day');
  },
  getDiffDaysCountActive: (dat) => {
    return myMoment(dat).diff(myMoment(), 'day');
  },
  getDiffDaysCountBetween: (dat1, dat2) => {
    return dat1.diff(dat2, 'day');
  },
  getDaysInMonth: (date) => {
    return myMoment(date).daysInMonth();
  },
  getDaysLeftInMonth: (date) => {
    return myMoment(date).daysInMonth() - myMoment(date).get('date');
  },
  getStartOfMonthByMonth: (month) => {
    return myMoment()
      .set('month', month)
      .startOf('month')
      .format('DD/MM/YYYY 00:00:00');
  },
  getStartOfMonth: (month, year) => {
    return myMoment()
      .set('month', month)
      .set('year', year)
      .startOf('month')
      .format('DD/MM/YYYY 00:00:00');
  },
  getStartOfMonthDate: (month, year) => {
    return myMoment()
      .set('month', month)
      .set('year', year)
      .set('hours', 0)
      .startOf('month');
  },
  getEndOfMonth: (month, year) => {
    return myMoment()
      .set('month', month)
      .set('year', year)
      .endOf('month')
      .format('DD/MM/YYYY 00:00:00');
  },
  getEndOfMonthDate: (month, year) => {
    return myMoment().set('month', month).set('year', year).endOf('month');
  },
  getDateDayCountFromDate: (today, dayCount = 1) => {
    return myMoment(today).add(dayCount, 'day');
  },
  getLastWeekStart: (today) => {
    return myMoment(today).subtract(1, 'weeks').startOf('week');
  },
  getLastWeekEnd: (today) => {
    return myMoment(today).subtract(1, 'weeks').endOf('week');
  },
  getThisWeekStart: (today) => {
    return myMoment(today).startOf('week');
  },
  getThisWeekEnd: (today) => {
    return myMoment(today).endOf('week');
  },
  getThisMonthStart: (today) => {
    return myMoment(today).startOf('month');
  },
  getThisMonthEnd: (today) => {
    return myMoment(today).endOf('month');
  },
  getLastMonthStart: (today) => {
    return myMoment(today).subtract(1, 'months').startOf('month');
  },
  getLastMonthEnd: (today) => {
    return myMoment(today).subtract(1, 'months').endOf('month');
  },
  getThisYearStart: (today) => {
    return myMoment(today).startOf('year');
  },
  getThisYearEnd: (today) => {
    return myMoment(today).endOf('year');
  },
  getThisYearStart2021: () => {
    return myMoment(myMoment('20.07.2021', 'DD.MM.YYYY').toDate()).startOf(
      'year'
    );
  },
  getThisYearRange: () => {
    return [
      UtilDate.getThisYearStart2021(),
      UtilDate.getThisYearEnd(UtilDate.getDateDayCount(15)),
    ];
  },
  isTimeBetween: (serverTime, startTime, endTime) => {
    let start = myMoment(startTime, 'H:mm');
    let end = myMoment(endTime, 'H:mm');
    let server = myMoment(serverTime, 'H:mm');
    if (end < start) {
      return (
        server >= (start && server <= myMoment('23:59:59', 'h:mm:ss')) ||
        (server >= myMoment('0:00:00', 'h:mm:ss') && server < end)
      );
    }
    return server >= start && server < end;
  },
  isBefore3AM: () => {
    return UtilDate.isTimeBetween(UtilDate.getDateToday(), '00:00:01', '03:00');
  },
  getMonthLongName: (month) => {
    let mom = myMoment();
    mom = mom.month(month);
    return mom.format('MMMM');
  },
  getMonthName: (value, isFull) => {
    if (!value) {
      value = myMoment();
    }
    if (isFull) {
      return value.format('MMMM');
    }
    return value.format('MMM');
  },
  getMonth: (value) => {
    if (!value) {
      value = myMoment();
    }
    return '' + value.month();
    // return value.format('MM');
  },

  getDayOfMonth: (value) => {
    if (!value) {
      value = myMoment();
    }
    return myMoment(value).date();
  },
  getDayCountOfMonthByDate: (date) => {
    if (!date) {
      date = myMoment();
    }
    return date.daysInMonth();
  },
  getLastMonthWithSameDay: (value) => {
    if (!value) {
      value = myMoment();
    }
    return UtilDate.getEndDateToday(value).subtract(1, 'months');
  },
  getEndDateToday: (date) => {
    if (!date) {
      date = myMoment();
    }
    return myMoment(date).endOf('day');
  },
  getStartDateToday: (date) => {
    if (!date) {
      date = myMoment();
    }
    return myMoment(date).startOf('day');
  },
  getMonthFullNameFormatWithoutYear: (date, isMonthFull) => {
    if (!date) {
      date = myMoment();
    }
    if (isMonthFull) {
      return myMoment(date).format('MMMM D');
    } else {
      return myMoment(date).format('MMM D');
    }
  },
  getWeekDayNameShort: (date) => {
    if (!date) {
      date = myMoment();
    }
    return myMoment(date).format('DD ddd');
  },
  getMonthFullNameFormat: (date) => {
    if (!date) {
      date = myMoment();
    }
    return date.format('MMM D, YYYY');
  },
  getDateWithCustomMinusPlusDays: (date, value) => {
    if (!date) {
      date = myMoment();
    }
    if (!value) {
      value = 1;
    }
    return myMoment().add(value, 'd');
  },
  getDateLabelForRange: (dates) => {
    if (dates?.length && dates?.[0] && dates?.[1]) {
      if (
        UtilDate.getStartDateToday(dates[0]).isSame(
          UtilDate.getStartDateToday()
        ) &&
        UtilDate.getStartDateToday(dates[1]).isSame(
          UtilDate.getStartDateToday(UtilDate.getDateToday())
        )
      ) {
        return 'Today';
      }

      if (
        UtilDate.getStartDateToday(dates[0]).isSame(
          UtilDate.getThisMonthStart()
        ) &&
        UtilDate.getStartDateToday(dates[1]).isSame(
          UtilDate.getStartDateToday(UtilDate.getDateToday())
        )
      ) {
        return 'This Month';
      }

      if (
        UtilDate.getStartDateToday(dates[0]).isSame(
          UtilDate.getStartDateToday(
            UtilDate.getDateWithCustomMinusPlusDays(false, -30)
          )
        ) &&
        UtilDate.getStartDateToday(dates[1]).isSame(
          UtilDate.getStartDateToday(UtilDate.getDateToday())
        )
      ) {
        return 'Last 30 Days';
      }

      return `${dates[0].format('DD MMM YYYY')} - ${dates[1].format(
        'DD MMM YYYY'
      )}`;
    }
    return '';
  },
};

export default UtilDate;
