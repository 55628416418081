import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size, color }) => {
  return (
    <svg
      width={size || '18'}
      height={size || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.42388 2.25C2.77581 2.25 2.25 2.78465 2.25 3.4428V4.14483C2.25 4.63235 2.43539 5.10119 2.76702 5.45383L6.40133 9.31824L6.40292 9.31581C7.10453 10.0341 7.49929 11.0051 7.49929 12.0175V15.4464C7.49929 15.6755 7.73902 15.8218 7.938 15.7137L10.0077 14.5859C10.3201 14.4153 10.5151 14.0838 10.5151 13.7238V12.0085C10.5151 11.0018 10.9043 10.0349 11.5995 9.31824L15.2338 5.45383C15.5646 5.10119 15.75 4.63235 15.75 4.14483V3.4428C15.75 2.78465 15.225 2.25 14.5769 2.25H3.42388Z"
        stroke={color || '#8870E6'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SVGFilterOutlined = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGFilterOutlined);
