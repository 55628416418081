import React, { useEffect, useState } from 'react';
import MySpace from '../../components/mySpace/MySpace';
import MyTextTitle from '../../components/myText/MyTextTitle';
import { useTranslation } from 'react-i18next';
import MyCard from '../../components/myCard/MyCard';
import MyTextSecondary from '../../components/myText/MyTextSecondary';
import ArrowSVG from '../../icons/ArrowSVG';
import MyText from '../../components/myText/MyText';
import UtilDate from '../../utils/UtilDate';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import StatisticsActions from '../../actions/StatisticsActions';
import UtilNotify from '../../utils/UtilNotify';
import UtilNumber from '../../utils/UtilNumber';
import { primaryColor, warningColor } from '../../theme/themeLightConfig';
import MyLineChart from '../../components/myLineChart/MyLineChart';
import LoadingIconApp from '../../components/loadingIconApp/LoadingIconApp';
import ActivityTransactionSection from './ActivityTransactionSection';
import { useSelector } from 'react-redux';
import MyDivider from '../../components/myDivider/MyDivider';

const ActivityDailySpending = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const [listData, setlistData] = useState([]);
  const [loading, setloading] = useState(true);
  const [selected, setselected] = useState();

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result =
        await StatisticsActions.getScreenDashboardSevenDaysChartData();
      if (isMounted()) {
        if (result.success) {
          setlistData(result.data);
          // UtilDate.getDateToday()
          setselected(result.data[result.data.length - 1]);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
      setloading(false);
    };
    fill();
  }, [isMounted]);

  const data = {
    labels: listData?.map((it) => {
      return UtilDate.getWeekDayNameShort(UtilDate.getDate(it.date));
    }),
    // labels: listData?.map((it) => {
    //   return UtilDate.getMonthFullNameFormatWithoutYear(
    //     UtilDate.getDate(it.date)
    //   );
    // }),
    datasets: [
      {
        data: listData?.map((it) => {
          return it?.valueExpense || 0;
        }),
        lineTension: 0.5,
        pointRadius: 0,
        borderColor: primaryColor,
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        data: listData?.map((it) => {
          return it?.valueExpenseLast || 0;
        }),
        lineTension: 0.5,
        pointRadius: 0,
        // borderColor: '#a7aab5',
      },
    ],
  };

  if (loading) {
    return <LoadingIconApp />;
  }

  return (
    <MySpace
      fullWidth
      direction="vertical"
      style={{
        flex: 1,
        // backgroundColor: 'green',
        width: sideMenuColapsed ? 300 : 100,
      }}
    >
      <MyCard>
        <MySpace direction="vertical" fullWidth>
          <MyTextTitle>{t('WORD_DAILY_SPENDING_OVERVIEW')}</MyTextTitle>
          <MySpace>
            <MyTextSecondary>{t('WORD_TREND_OF_SPENDING')}</MyTextSecondary>
            <ArrowSVG color="#a7aab5" />
            <MyText>
              {UtilDate.getMonthFullNameFormatWithoutYear(selected?.date)}
            </MyText>
          </MySpace>
          <MyDivider />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              gap: 7,
            }}
          >
            <MyText bold style={{ color: warningColor }}>
              {UtilNumber.formatDouble(selected?.valueExpense)}
            </MyText>
            <MyText size={16} bold>
              {t('WORD_SPENT_IN')}
            </MyText>
            <MyText bold style={{ color: primaryColor }}>
              {selected?.count}
            </MyText>
            <MyText size={16} bold>
              {t('WORD_TRANSACTION')}
            </MyText>
          </div>
          <MySpace>
            <MyTextSecondary>
              {UtilNumber.formatDouble(selected?.valueExpenseLast)}
            </MyTextSecondary>
            <MyTextSecondary>{t('WORD_SPENT_IN')}</MyTextSecondary>
            <MyTextSecondary>{selected?.countLast}</MyTextSecondary>
            <MyTextSecondary>{t('WORD_TRANSACTION_SPENT_IN')}</MyTextSecondary>
            <MyTextSecondary>
              {UtilDate.getMonthFullNameFormatWithoutYear(selected?.dateLast)}
            </MyTextSecondary>
          </MySpace>
          <MyLineChart
            data={data}
            onSelect={(e) => {
              let item = listData[e];
              setselected(item);
            }}
          />
        </MySpace>
      </MyCard>
      <ActivityTransactionSection date={selected?.date} />
    </MySpace>
  );
};

export default ActivityDailySpending;
