import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import MyDrawer from '../../components/myDrawer/MyDrawer';
import FormApp from '../../components/formApp/FormApp';
import InputFormRangeDatePicker from '../../components/inputFormRangeDatePicker/InputFormRangeDatePicker';
import InputTextFormItem from '../../components/myInputForm/InputTextFormItem';
import SelectAccount from '../../components/selects/SelectAccount';
import SelectPayee from '../../components/selects/SelectPayee';
import SelectTransactionComplete from '../../components/selects/SelectTransactionComplete';
import SelectTransactionType from '../../components/selects/SelectTransactionType';
import MyButtonSecondary from '../../components/myButton/MyButtonSecondary';
import UtilDate from '../../utils/UtilDate';
import SelectCategoryMultiSelect from '../../components/selects/SelectCategoryMultiSelect';
import SVGFilterOutlined from '../../icons/SVGFilterOutlined';
import colors from '../../Color.scss';

const formName = 'formTransaction';

const TransactionFilterDrawer = forwardRef((props, ref) => {
  const {
    onChange,
    loading,
    filter,
    disableAccount,
    disableCategory,
    onReset,
  } = props;

  const { t } = useTranslation();
  const [form] = useForm();

  const refDrawer = useRef();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(filter);
  }, [form, filter]);

  const onFinish = (values) => {
    onChange && onChange(values);
  };

  useImperativeHandle(ref, () => ({
    showDrawer: () => {
      if (refDrawer.current) {
        refDrawer.current.showDrawer && refDrawer.current.showDrawer();
      }
    },
  }));

  const handleReset = () => {
    onReset && onReset();
    refDrawer.current.closeDrawer();
  };

  return (
    <MyDrawer
      ref={refDrawer}
      label="More Filters"
      icon={<SVGFilterOutlined color={colors.bluishColor} />}
      loading={loading}
      // forceRender={false}
      // destroyOnClose={true}
      formName={formName}
    >
      <FormApp
        onFinish={onFinish}
        name={formName}
        form={form}
        initialValues={{}}
      >
        {
          <>
            <InputFormRangeDatePicker
              name="DateRange"
              label="Date"
              onChange={(e, ee, eee) => {
                let dat = '';
                if (e) {
                  if (e[0]) {
                    dat = `From ${UtilDate.formatDate(e[0])}`;
                  }
                  if (e[1]) {
                    dat += ` To ${UtilDate.formatDate(e[1])}`;
                  }
                }
                form.setFieldsValue({
                  tag__DateRange: dat.trim(),
                  dateFrom: ee,
                  dateTo: eee,
                });
              }}
            />
            <InputTextFormItem name="tag__DateRange" hidden />
            <InputTextFormItem name="dateFrom" hidden />
            <InputTextFormItem name="dateTo" hidden />
            <SelectCategoryMultiSelect
              name={'idCategory'}
              label={t('WORD_CATEGORY')}
              className="filter-select-category"
              allowClear={true}
              // onlySelect
              disabled={disableCategory}
              onChangeItem={(e) => {
                form.setFieldsValue({ tag__idCategory: e?.children });
              }}
            />
            <InputTextFormItem name="tag__idCategory" hidden />
            <SelectAccount
              name={'idAccount'}
              label={t('WORD_ACCOUNT')}
              allowClear={true}
              disabled={disableAccount}
              onChangeItem={(e) => {
                form.setFieldsValue({ tag__idAccount: e?.children });
              }}
              mode={'multiple'}
            />
            <InputTextFormItem name="tag__idAccount" hidden />
            <SelectPayee
              name={'idPayee'}
              label={t('WORD_PAYEE')}
              onlySelect
              allowClear={true}
              onChangeItem={(e) => {
                form.setFieldsValue({ tag__idPayee: e?.children });
              }}
              mode={'multiple'}
            />
            <InputTextFormItem name="tag__idPayee" hidden />
            <SelectTransactionComplete
              name="approved"
              label={t('WORD_CLEARED')}
              allowClear={true}
              onChangeItem={(e) => {
                form.setFieldsValue({ tag__approved: e?.children });
              }}
            />
            <InputTextFormItem name="tag__approved" hidden />
            <SelectTransactionType
              name="type"
              label={t('WORD_TYPE')}
              allowClear={true}
              onChangeItem={(e) => {
                form.setFieldsValue({ tag__type: e?.children });
              }}
              mode={'multiple'}
            />
            <InputTextFormItem name="tag__type" hidden />
          </>
        }
        <MyButtonSecondary
          fullWidth
          onClick={handleReset}
          label={'Reset'}
          icon={<ReloadOutlined />}
        />
      </FormApp>
    </MyDrawer>
  );
});

export default TransactionFilterDrawer;
