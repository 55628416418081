import React from 'react';
import { Collapse } from 'antd';
import ArrowSVG from '../../icons/ArrowSVG';
import LoadingIconApp from '../loadingIconApp/LoadingIconApp';
import '../myDropDown/MyDropDown.scss';

const MyCollapse = ({
  items = [],
  className = '',
  defaultActiveKey,
  bordered = false,
  collapsible = 'icon',
  onChange,
  expandIconPosition,
  loading,
  style,
}) => {
  return (
    <Collapse
      className={`my-collapse ${className}`}
      expandIconPosition={expandIconPosition}
      bordered={bordered}
      // style={style}
      ghost
      collapsible={collapsible}
      expandIcon={({ isActive }) =>
        loading ? (
          <LoadingIconApp />
        ) : (
          // <div>
          <div className="arrow-bg-always" style={style}>
            <ArrowSVG rotate={isActive ? 180 : 0} color="#a7aab5" />
          </div>
          // </div>
        )
      }
      items={items}
      defaultActiveKey={defaultActiveKey}
      onChange={onChange}
    />
  );
};

export default MyCollapse;
