import React, { useCallback, useEffect, useState } from 'react';
import MyMonthPicker from '../../components/myMonthPicker/MyMonthPicker';
import UtilDate from '../../utils/UtilDate';
import ActivityShare from './ActivityShare';
import ActivityBudgetOverview from './ActivityBudgetOverview';
import ActivityAmountBlock from './ActivityAmountBlock';
import ActivityBlockCard from './ActivityBlockCard';
import { useTranslation } from 'react-i18next';
import MyPageHeader from '../../components/myPageHeader/MyPageHeader';
import ActivityDailySpending from './ActivityDailySpending';
import UtilNumber from '../../utils/UtilNumber';
import UtilNotify from '../../utils/UtilNotify';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import StatisticsActions from '../../actions/StatisticsActions';
import CategoriesActions from '../../actions/CategoriesActions';
import LoadingIconApp from '../../components/loadingIconApp/LoadingIconApp';
import { useSelector } from 'react-redux';
import TransactionActions from '../../actions/TransactionActions';
import MySpace from '../../components/mySpace/MySpace';
import MyDefaultPageLayout from '../../components/myDefaultPageLayout/MyDefaultPageLayout';

const Activity = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const budgetName = useSelector((state) => {
    return state.currentDbSettings.displayName || '';
  });

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const [selectedMonth, setSelectedMonth] = useState(UtilDate.getThisMonth());
  const [selectedYear, setSelectedYear] = useState(UtilDate.getThisYear());
  const [loading, setloading] = useState(true);
  const [dataAmounts, setdataAmounts] = useState();
  const [totals, settotals] = useState();

  const fill = useCallback(async () => {
    setloading(true);
    let result = await StatisticsActions.getScreenDashboardGetTotal({
      filter: { month: selectedMonth, year: selectedYear },
    });
    if (isMounted()) {
      if (result.success) {
        const previousBalance = result.data.thisMonthStartingBalance;
        const currentBalance = result.data.total;

        const percentageTotal = UtilNumber.calcTwoNumbersPercentageDiff(
          currentBalance,
          previousBalance
        );

        const percentageExpense = UtilNumber.calcTwoNumbersPercentageDiff(
          result.data.thisMonthSpent,
          result.data.lastMonthSpent
        );

        const percentageIncome = UtilNumber.calcTwoNumbersPercentageDiff(
          result.data.thisMonthIncome,
          result.data.lastMonthIncome
        );

        let dataBlockList = [
          {
            _id: 1,
            typeIncomeExpense: CategoriesActions.TYPEINCOMEEXPENSE.INCOME,
            percentage: percentageIncome,
            amount: result.data.thisMonthIncome,
            label: 'Income',
            state: { type: TransactionActions.TYPE.INCOME },
          },
          {
            _id: 2,
            typeIncomeExpense: CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE,
            percentage: percentageExpense,
            amount: result.data.thisMonthSpent,
            label: 'Expense',
            state: { type: TransactionActions.TYPE.EXPENSE },
          },
        ];

        if (result.data.isSavingAccExist) {
          const percentageSavingChange =
            UtilNumber.calcTwoNumbersPercentageDiff(
              result.data.thisMonthTotalSaving,
              result.data.lastMonthTotalSaving
            );

          dataBlockList.push({
            _id: 3,
            typeIncomeExpense: CategoriesActions.TYPEINCOMEEXPENSE.INCOME,
            percentage: percentageSavingChange,
            amount: result.data.thisMonthTotalSaving,
            label: 'Saving',
            disabled: true,
          });
        }

        if (result.data.isLoanAccExist) {
          const percentageLoanChange = UtilNumber.calcTwoNumbersPercentageDiff(
            result.data.thisMonthTotalLoan,
            result.data.lastMonthTotalLoan
          );

          dataBlockList.push({
            _id: 4,
            typeIncomeExpense: CategoriesActions.TYPEINCOMEEXPENSE.INCOME,
            percentage: percentageLoanChange,
            amount: result.data.thisMonthTotalLoan,
            label: 'Loan',
            disabled: true,
          });
        }

        if (result.data.isInvestmentAccExist) {
          const percentageInvestmentChange =
            UtilNumber.calcTwoNumbersPercentageDiff(
              result.data.thisMonthTotalInvestment,
              result.data.lastMonthTotalInvestment
            );

          dataBlockList.push({
            _id: 4,
            typeIncomeExpense: CategoriesActions.TYPEINCOMEEXPENSE.INCOME,
            percentage: percentageInvestmentChange,
            amount: result.data.thisMonthTotalInvestment,
            label: 'Investment',
            disabled: true,
          });
        }

        setdataAmounts(dataBlockList);

        const totalDate = UtilDate.getDate();

        let dayOfMonth = UtilDate.getDayOfMonth(totalDate);
        let dayCount = UtilDate.getDayCountOfMonthByDate(totalDate);
        let shouldBePerc = UtilNumber.calcTwoNumbersPercentage(
          (result.data.thisMonthBudgeted / dayCount) * dayOfMonth,
          result.data.thisMonthBudgeted + result.data.overspentThisMonth
        );

        settotals({
          ...result.data,
          percentageTotal,
          percentageExpense,
          percentageIncome,
          shouldBePerc:
            selectedMonth === UtilDate.getMonth() ? shouldBePerc : 0,
        });
        setloading(false);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  }, [isMounted, selectedMonth, selectedYear]);

  useEffect(() => {
    fill();
  }, [fill]);

  return (
    <MyDefaultPageLayout
      helmetTitle={`${t('WORD_ACTIVITY')} | ${budgetName} | ${t(
        'WORD_MONEYSCOPE'
      )} `}
      content={
        `${t('WORD_BUDGET')}` | `${budgetName}` | `${t('WORD_MONEYSCOPE')}`
      }
    >
      <MySpace direction="vertical" size="middle" fullWidth>
        <MyPageHeader
          title={
            <MyMonthPicker
              onChange={(month, year) => {
                setSelectedMonth(month);
                setSelectedYear(year);
              }}
            />
          }
          extraRight={<ActivityShare />}
        />
        {loading ? (
          <LoadingIconApp />
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              // marginTop: 20,
              // gap: 25,
            }}
          >
            <div
              style={{
                width: sideMenuColapsed
                  ? 'calc(100% - 340px)'
                  : 'calc(100% - 340px)',
                // backgroundColor: 'red',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                {dataAmounts?.map((obj, index) => {
                  return (
                    <ActivityAmountBlock
                      key={index}
                      month={selectedMonth}
                      year={selectedYear}
                      item={obj}
                    />
                  );
                })}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // justifyContent: 'space-between',
                  // width: sideMenuColapsed ? '96%' : '76%',
                  marginTop: 15,
                  gap: 10,
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                >
                  <ActivityBlockCard
                    title={t('WORD_CURRENT_BALANCE')}
                    amount={totals?.total}
                    label={t('WORD_FUNDS_AVAILABLE_BUDGET')}
                  />
                  <ActivityBlockCard
                    title={t('WORD_NET_WORTH')}
                    amount={totals?.currentNetWorth}
                    label={t('WORD_INFLATION_RATE')}
                    hasPercent={true}
                    percentValue={UtilNumber.calcTwoNumbersPercentageDiff(
                      totals?.currentNetWorth,
                      totals?.currentNetWorthLast
                    )}
                  />
                </div>
                <ActivityDailySpending />
              </div>
            </div>
            <ActivityBudgetOverview
              totals={totals}
              month={selectedMonth}
              year={selectedYear}
            />
          </div>
        )}
      </MySpace>
    </MyDefaultPageLayout>
  );
};

export default Activity;
