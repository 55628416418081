import React from 'react';
import ArrowSVG from '../../icons/ArrowSVG';
import MyText from '../myText/MyText';
import MySpace from '../mySpace/MySpace';
import MenuSVG from '../../icons/MenuSVG';
import useWindowSize from '../../utils/useWindowSize';
import ApiStorage from '../../redux/ApiStorage';
import { useSelector } from 'react-redux';

const MyPageHeader = (props) => {
  const { title, extraLeft, extraRight, subTitle, onBack, style } = props;

  const setSidePanelOpen = useSelector((state) => {
    return state.setSidePanelOpen;
  });

  const { isDesktop } = useWindowSize();

  return (
    <MySpace fullWidth spaceBetween>
      <MySpace style={{ gap: 15 }}>
        {Boolean(!isDesktop) && (
          <MenuSVG
            onClick={() => {
              ApiStorage.setSidePanelOpen(!setSidePanelOpen);
            }}
          />
        )}
        <MySpace size={'middle'} style={{ ...style, display: 'flex' }}>
          {onBack && (
            <MyText className="arrow-icon">
              <ArrowSVG onClick={onBack} rotate={90} />
            </MyText>
          )}
          <MyText fontWeight={500} size={20}>
            {title}
          </MyText>
        </MySpace>
        <MyText>{subTitle}</MyText>
        <div>{extraLeft}</div>
      </MySpace>
      <div>{extraRight}</div>
    </MySpace>
  );
};

export default MyPageHeader;
