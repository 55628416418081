import { Flex } from 'antd';
import React from 'react';

const MyDefaultCenteredColumn = ({ children }) => {
  return (
    <Flex align="center" justify="center">
      {children}
    </Flex>
  );
};

export default MyDefaultCenteredColumn;
