import { Table } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import './InfiniteScrollTable.scss';

const InfiniteScrollTable = forwardRef(
  (
    {
      dataSource,
      onLoadMore,
      columns,
      rowKey,
      dataLength,
      height,
      onRowClick,
      onRowDoubleClick,
      selectedRow,
      className,
      onChange,
      loading,
      rowClassName,
      expandable,
      rowSelection,
      getScrollParent,
      initialLoad,
      pageStart,
      isReverse,
    },
    ref
  ) => {
    const doubleClickTimerRef = useRef(null);
    const editingRowRef = useRef(null);
    const scrollContainerRef = useRef(null);

    useImperativeHandle(ref, () => ({
      scrollTop: () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo(0, 0);
        }
      },
    }));

    const handleRowClick = (rowIndex, record, event) => {
      // If the row is currently being edited, prevent the click from triggering onRowClick
      // if (editingRowRef.current === rowIndex) {
      //   return;
      // }
      if (selectedRow === rowIndex) {
        return;
      }

      // If a double-click timer is already set, clear it and trigger the double-click event
      if (doubleClickTimerRef.current) {
        clearTimeout(doubleClickTimerRef.current);
        doubleClickTimerRef.current = null;
        return;
      }

      // Set a new timer for double-click
      doubleClickTimerRef.current = setTimeout(() => {
        doubleClickTimerRef.current = null;
        onRowClick && onRowClick(rowIndex, record, event);
      }, 200); // Adjust this value based on your desired double-click interval
    };

    const handleRowDoubleClick = (rowIndex, record, event) => {
      clearTimeout(doubleClickTimerRef.current);
      doubleClickTimerRef.current = null;
      setEditingRow(rowIndex); // Set the editingRow flag on double-click
      onRowDoubleClick && onRowDoubleClick(rowIndex, record, event);
    };

    const setEditingRow = (rowIndex) => {
      editingRowRef.current = rowIndex;
    };

    const handleCellClick = (rowIndex, record, event) => {
      // If the row is currently being edited, prevent the click from triggering onRowClick
      // if (editingRowRef.current === rowIndex) {
      //   return;
      // }
      if (selectedRow === rowIndex) {
        return;
      }
      // Handle your cell click logic here
      // ...
    };

    return (
      <InfiniteScroll
        ref={ref}
        initialLoad={initialLoad}
        pageStart={pageStart}
        loadMore={() => {
          if (!loading) {
            onLoadMore && onLoadMore();
          }
        }}
        isReverse={isReverse}
        hasMore={!loading && dataSource?.length < dataLength}
        useWindow={false}
        threshold={150}
        className={`my_default_infinite_scroll ${className || ''}`}
        getScrollParent={getScrollParent}
        height={height}
      >
        <Table
          className={`InfiniteScrollTable-table ${className || ''}`}
          columns={columns}
          dataSource={dataSource || []}
          rowKey={rowKey}
          pagination={false}
          onChange={onChange}
          loading={loading}
          expandable={expandable}
          rowClassName={(record, rowIndex) => {
            return (
              rowClassName ||
              `table-row ${rowIndex === selectedRow ? ' selected' : ''}`
            );
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleRowClick(rowIndex, record, event);
              },
              onDoubleClick: (event) => {
                handleRowDoubleClick(rowIndex, record, event);
              },
            };
          }}
          onCell={(record, rowIndex) => ({
            onClick: (event) => {
              handleCellClick(rowIndex, record, event);
            },
          })}
          rowSelection={rowSelection}
        />
      </InfiniteScroll>
    );
  }
);

export default InfiniteScrollTable;
