import { Dropdown } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MyButtonMain from '../../components/myButton/MyButtonMain';
import ArrowSVG from '../../icons/ArrowSVG';
import MyText from '../../components/myText/MyText';
import CategoryCreateNewButton from './CategoryCreateNewButton';
import PlusBtnSVG from '../../icons/PlusBtnSVG';
import MySpaceColumn from '../../components/mySpace/MySpaceColumn';
import MySpace from '../../components/mySpace/MySpace';
import MyTextSecondary from '../../components/myText/MyTextSecondary';

const CategoryAddNewSelect = ({ onChange }) => {
  const { t } = useTranslation();

  const createNewCategoryMenuItems = [
    {
      label: (
        <CategoryCreateNewButton
          isNew
          isGroup
          trigger={
            <MySpace>
              <MySpaceColumn>
                <MyText size={18}>{t('WORD_CREAT_A_GROUP')}</MyText>
                <MyTextSecondary>
                  {t('WORD_GROUP_CAN_CONTAIN_MULTIPLE_CATEGORIES')}
                </MyTextSecondary>
              </MySpaceColumn>
              <ArrowSVG color="#a7aab5" />
            </MySpace>
          }
          onChange={onChange}
        />
      ),
      key: 'CreateGroup',
      className: 'drop-item-wrapper',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <CategoryCreateNewButton
          isNew
          isGroup={false}
          trigger={
            <MySpace>
              <MySpaceColumn>
                <MyText size={18}>{t('WORD_CREATE_A_CATEGORY')}</MyText>
                <MyTextSecondary>
                  {t('WORD_USED_IN_TRANSACTION_BUDGET_GOALS_ETC')}
                </MyTextSecondary>
              </MySpaceColumn>
              <ArrowSVG color="#a7aab5" />
            </MySpace>
          }
          onChange={onChange}
        />
      ),
      key: 'CreateCategory',
      className: 'drop-item-wrapper',
    },
  ];

  return (
    <Dropdown
      placement="bottomRight"
      // arrow={true}
      menu={{ items: createNewCategoryMenuItems }}
      trigger={['click']}
      dropdownRender={(menu) => <div className="dropdown-menu">{menu}</div>}
      onClick={(e) => e.preventDefault()}
    >
      <div onClick={(e) => e.preventDefault()}>
        <MyButtonMain
          label={t('WORD_ADD_CATEGORY')}
          icon={<PlusBtnSVG isMainButtonIcon />}
        />
      </div>
    </Dropdown>
  );
};

export default memo(CategoryAddNewSelect);
