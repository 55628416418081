export const primaryColor = '#8870e6';
export const successColor = '#2cb37a';
export const errorColor = '#e2647b';
export const warningColor = '#f90';
export const whiteColor = '#fff';

const themeLightConfig = {
  colorPrimary: primaryColor,
  borderRadius: 12,
  wireframe: false,
  controlHeight: 40,

  colorLink: primaryColor,
  // colorBorder: primaryColor,
  colorText: '#2e3e5f',

  // colorPrimaryBg: '#d6dee1',
  // colorBgBase: '#f4f6fa',
  // controlItemBgActive: 'transparent',

  colorBgLayout: '#f4f6fa',

  colorBgContainer: 'white',

  // colorIcon: 'rgba(0,0, 0, 0.45)',
  // fillIcon: 'red',
  // colorIconHover: 'rgba(0,0,0, 1)',
};

export default themeLightConfig;
