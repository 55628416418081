import React, { useEffect, useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import FormApp from '../../../components/formApp/FormApp';
import { useForm } from 'antd/es/form/Form';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MySpace from '../../../components/mySpace/MySpace';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyText from '../../../components/myText/MyText';
import MyButtonSecondary from '../../../components/myButton/MyButtonSecondary';
import MyButtonDelete from '../../../components/myButton/MyButtonDelete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import BudgetActions from '../../../actions/BudgetActions';
import UtilNotify from '../../../utils/UtilNotify';
import ApiStorage from '../../../redux/ApiStorage';
import { useSelector } from 'react-redux';
import SelectCurrency from '../../../components/selects/SelectCurrency';
import SelectNumberFormat from '../../../components/selects/SelectNumberFormat';
import SelectCurrencyPlacement from '../../../components/selects/SelectCurrencyPlacement';
import SelectDateFormat from '../../../components/selects/SelectDateFormat';
import MyDefaultPageLayout from '../../../components/myDefaultPageLayout/MyDefaultPageLayout';

const formName = 'create-budget-form';

const BudgetEdit = () => {
  const currentDbSettings = useSelector((state) => {
    return state.currentDbSettings;
  });

  const currentDb = useSelector((state) => {
    return state.currentDb;
  });

  const canEdit = useSelector((state) => state.canEdit);

  const [form] = useForm();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ ...currentDbSettings, dbName: currentDb.dbName });
  }, [currentDbSettings, currentDb, form]);

  const onFinish = async (values) => {
    setSaving(true);
    let result = await BudgetActions.updateBudget(values);
    if (isMounted()) {
      if (result.success) {
        UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
        ApiStorage.setCurrentDbSettings(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    if (currentDb) {
      setSaving(true);
      let result = await BudgetActions.deleteBudget(currentDb.dbName);
      if (isMounted()) {
        if (result.success) {
          UtilNotify.notifywarning(t('WORD_DELETED_SUCCESSFULY'));
          navigate(`/dbbudget/list/first`);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
          setSaving(false);
        }
      }
    }
  };

  return (
    <MyDefaultPageLayout>
      <MyCard style={{ height: 950, width: 600 }}>
        <MyPageHeader
          title={t('WORD_BUDGET_SETTINGS')}
          style={{ marginBottom: 10 }}
        />
        <MySpace direction="vertical" fullWidth>
          <FormApp name={formName} form={form} onFinish={onFinish}>
            <InputTextFormItem
              name="displayName"
              required
              label={t('WORD_DISPLAY_NAME')}
              readOnly={!canEdit}
            />
            <InputTextFormItem
              name="dbName"
              required
              hidden
              placeholder={t('WORD_DB_NAME')}
            />
            <SelectCurrency
              name={'idCurrency'}
              required
              label={t('WORD_CURRENCY')}
              disabled={!canEdit}
            />
            <SelectNumberFormat
              name="formatNumber"
              required
              label={t('WORD_FORMAT_NUMBER')}
              placeholder={t('WORD_FORMAT_NUMBER')}
              disabled={!canEdit}
            />
            <SelectCurrencyPlacement
              name={'currencyPlacement'}
              required
              placeholder={t('WORD_CURRENCY_PLACEMENT')}
              label={t('WORD_CURRENCY_PLACEMENT')}
              disabled={!canEdit}
            />
            <SelectDateFormat
              label={t('WORD_FORMAT_DATE')}
              name="formatDate"
              placeholder={t('WORD_FORMAT_DATE')}
              required
            />
          </FormApp>
          <MyButtonMain
            form={formName}
            label={t('WORD_UPDATE_BUDGET')}
            fullWidth
            loading={saving}
            disabled={!canEdit}
          />
          {Boolean(currentDb.isOwner) && (
            <>
              <MySpace
                direction="horizontal"
                fullWidth
                style={{
                  marginTop: 25,
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <MySpace direction="vertical" fullWidth>
                  <MyTextTitle size={20}>{t('WORD_EXPORT_BUDGET')}</MyTextTitle>
                  <MyText>{t('WORD_EXPORT')}</MyText>
                </MySpace>
                <MyButtonSecondary label="Export" style={{ width: 140 }} />
              </MySpace>
              <MySpace
                direction="horizontal"
                fullWidth
                style={{
                  marginTop: 25,
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <MySpace direction="vertical" fullWidth>
                  <MyTextTitle size={20}>{t('WORD_SHARE_BUDGET')}</MyTextTitle>
                  <MyText>{t('WORD_SHARE')}</MyText>
                </MySpace>
                <MyButtonSecondary
                  label={t('WORD_SHARE_BUDGET')}
                  onClick={() => navigate(`/dbbudget/share`)}
                  style={{ width: 140 }}
                />
              </MySpace>
              <MySpace
                direction="horizontal"
                fullWidth
                style={{
                  marginTop: 25,
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <MySpace direction="vertical" fullWidth>
                  <MyTextTitle size={20}>{t('WORD_DELETE_BUDGET')}</MyTextTitle>
                  <MyText>{t('WORD_DELETE')}</MyText>
                </MySpace>
                <MyButtonDelete
                  loading={saving}
                  label={t('WORD_DELETE_BUDGET')}
                  onClick={handleDelete}
                />
              </MySpace>
            </>
          )}
        </MySpace>
      </MyCard>
    </MyDefaultPageLayout>
  );
};

export default BudgetEdit;
