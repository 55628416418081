import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8.82491"
        cy="8.82495"
        r="6.74142"
        stroke="#A7AAB5"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5137 13.8638L16.1567 16.5"
        stroke="#A7AAB5"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SVGSearchIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGSearchIcon);
