import { Tag } from 'antd';
import React from 'react';
import SVGRemoveFilterIcon from '../../icons/SVGRemoveFilterIcon';
import './MyTag.scss';

const MyTag = ({ title, className, icon, disableClose, onClose, onClick }) => {
  return (
    <Tag
      className={`my_default_tag ${className || ''}`}
      onClick={onClick}
      icon={icon}
      closeIcon={
        disableClose ? null : <SVGRemoveFilterIcon onClick={onClose} />
      }
    >
      {title}
    </Tag>
  );
};

export default MyTag;
