import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ color, size }) => {
  return (
    <svg
      width={size || 28}
      height={size || 28}
      viewBox="0 0 28 28"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43263 18.3077C8.24183 18.3077 8.04984 18.24 7.88766 18.1019C7.49652 17.766 7.42259 17.1407 7.72429 16.7053L11.2934 11.5409C11.4389 11.3298 11.6547 11.1931 11.8909 11.1599C12.1317 11.1254 12.3679 11.1997 12.5551 11.3657L15.9179 14.3063L18.8598 10.0806C19.1627 9.64377 19.7232 9.56279 20.1143 9.90265C20.5054 10.2399 20.577 10.8652 20.2741 11.2993L16.7801 16.3176C16.6346 16.5274 16.4199 16.6641 16.1838 16.696C15.9453 16.7318 15.7092 16.6548 15.5208 16.4915L12.1604 13.5522L9.14216 17.9187C8.96567 18.1736 8.70094 18.3077 8.43263 18.3077Z"
        fill="#A7AAB5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7974 6.46154H24.7413C22.9731 6.4464 21.5386 5.00279 21.5386 3.23022C21.5386 1.44828 22.9883 0 24.7693 0C26.5425 0 27.9853 1.43354 28 3.20297V3.25748C27.9854 5.01757 26.5577 6.44648 24.7974 6.46154ZM23.3521 3.23074C23.3521 2.44857 23.9881 1.81389 24.7692 1.81389C25.5503 1.81389 26.1863 2.44857 26.1863 3.23074C26.1863 4.0117 25.5503 4.6488 24.7692 4.6488C23.9881 4.6488 23.3521 4.0117 23.3521 3.23074Z"
        fill="#A7AAB5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1318 25.8461H8.86849C4.85216 25.8461 2.15393 23.0249 2.15393 18.8259V9.18483C2.15393 4.97983 4.85216 2.15384 8.86849 2.15384H17.5381C18.032 2.15384 18.4328 2.55466 18.4328 3.04852C18.4328 3.54238 18.032 3.9432 17.5381 3.9432H8.86849C5.87682 3.9432 3.94321 5.99977 3.94321 9.18483V18.8259C3.94321 22.0527 5.8303 24.0568 8.86849 24.0568H19.1318C22.1235 24.0568 24.0571 22.0038 24.0571 18.8259V10.429C24.0571 9.93517 24.4579 9.53435 24.9517 9.53435C25.4456 9.53435 25.8464 9.93517 25.8464 10.429V18.8259C25.8464 23.0249 23.1481 25.8461 19.1318 25.8461Z"
        fill="#A7AAB5"
      />
    </svg>
  );
};

const ActivityOutlineSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ActivityOutlineSVG);
