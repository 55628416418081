import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || '36'}
      height={size || '36'}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#8870E6" />
      <path
        d="M11.7 17.8412L16.65 22.5L24.3 15.3"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SVGTableSelectedIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGTableSelectedIcon);
