import { DatePicker } from 'antd';
import React, { memo, useState } from 'react';
import UtilDate from '../../utils/UtilDate';
import ArrowSVG from '../../icons/ArrowSVG';
import MyText from '../myText/MyText';
import MyCard from '../myCard/MyCard';
import MySpace from '../mySpace/MySpace';
import './MyMonthPicker.scss';

const MyMonthPicker = (props) => {
  const { onChange } = props;

  const [date, setdate] = useState(UtilDate.getDateToday());

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleOnChange = (e) => {
    let d = e['$d'];
    let mom = UtilDate.getDateToday()
      .set('date', d.getDate())
      .set('month', d.getMonth())
      .set('year', d.getFullYear());
    setdate(mom);
    if (date) {
      onChange && onChange(date.month(), date.year());
    }
  };

  const handelChange = (dat) => {
    onChange && onChange(dat.month(), dat.year());
  };

  return (
    <>
      <MyCard className="month-picker-card">
        <MySpace>
          <div
            className="arrow-bg"
            onClick={() => {
              let d = UtilDate.getDatePrevMonth(date);
              setdate(d);
              handelChange(d);
            }}
          >
            <ArrowSVG color="#a7aab5" rotate={90} />
          </div>
          <div
            className="datePickerOpenText"
            onClick={() => setDatePickerOpen(true)}
          >
            <MyText size={15} style={{ fontWeight: 500 }}>
              {UtilDate.formatMonthYear(date)}
            </MyText>
          </div>
          <DatePicker
            className="my-month-datePicker"
            picker="month"
            onChange={handleOnChange}
            open={datePickerOpen}
            onOpenChange={setDatePickerOpen}
          />
          <div
            className="arrow-bg"
            onClick={() => {
              let d = UtilDate.getDateNextMonth(date);
              setdate(d);
              handelChange(d);
            }}
          >
            <ArrowSVG rotate={-90} color="#a7aab5" />
          </div>
        </MySpace>
      </MyCard>
    </>
  );
};

export default memo(MyMonthPicker);
