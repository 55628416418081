import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill="#FF9900"
      />
      <path
        d="M5.06391 10.5H9.01881C9.23815 10.5 9.41597 10.3209 9.41597 10.1V9.03145C9.41597 8.94606 9.38884 8.86292 9.33854 8.79417L8.19322 7.2287C8.09302 7.09175 8.08979 6.90595 8.18518 6.76555L9.2473 5.20213C9.2925 5.1356 9.31668 5.05687 9.31668 4.97625V3.9C9.31668 3.67909 9.13886 3.5 8.91952 3.5H5.06391C4.84456 3.5 4.66675 3.67909 4.66675 3.9V4.96855C4.66675 5.05394 4.69388 5.13708 4.74417 5.20583L5.88322 6.76272C5.98645 6.90382 5.98645 7.09618 5.88322 7.23728L4.74417 8.79417C4.69388 8.86292 4.66675 8.94606 4.66675 9.03145V10.1C4.66675 10.3209 4.84456 10.5 5.06391 10.5Z"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41675 8.75H7.58342"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const SVGPendingTransactionIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGPendingTransactionIcon);
