import React from 'react';
import { Dropdown, Flex } from 'antd';
import SVGDropdownArrow from '../../icons/SVGDropdownArrow';
import './MyDefaultDropdownButton.scss';

const MyDefaultDropdownButton = ({
  title,
  dropdownMenuItems,
  onItemClick,
  onClick,
  className,
  titleIcon,
}) => {
  return (
    <Dropdown.Button
      className={`my_default_dropdown_button ${className || ''}`}
      overlayClassName="my_default_dropdown_button_dropdown"
      type="primary"
      onClick={onClick}
      menu={{
        items: dropdownMenuItems || [],
        onClick: onItemClick,
      }}
      icon={
        <SVGDropdownArrow
          style={{
            pointerEvents: 'none',
          }}
          color={'white'}
        />
      }
      trigger={['click']}
    >
      {titleIcon ? (
        <Flex gap={10} align="center" justify="center">
          {titleIcon}
          {title}
        </Flex>
      ) : (
        title
      )}
    </Dropdown.Button>
  );
};

export default MyDefaultDropdownButton;
