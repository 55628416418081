import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || '28'}
      height={size || '28'}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3846 12.6309C26.3846 12.0786 25.9369 11.6309 25.3846 11.6309H20.5154C19.9299 11.6309 19.3683 11.8635 18.9543 12.2775C18.5403 12.6915 18.3077 13.2531 18.3077 13.8386C18.3077 14.4241 18.5403 14.9856 18.9543 15.3997C19.3683 15.8137 19.9299 16.0463 20.5154 16.0463H25.3846C25.9369 16.0463 26.3846 15.5986 26.3846 15.0463V12.6309Z"
        fill="#8870E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3354 8.92782C15.3243 8.69831 15.2253 8.48187 15.0588 8.32345C14.8924 8.16502 14.6713 8.07679 14.4415 8.07705H7.98C7.74579 8.07705 7.52118 8.17009 7.35557 8.3357C7.18996 8.50131 7.09692 8.72593 7.09692 8.96013C7.09692 9.19434 7.18996 9.41895 7.35557 9.58456C7.52118 9.75017 7.74579 9.84321 7.98 9.84321H14.4415C14.5612 9.84475 14.6799 9.82196 14.7904 9.77622C14.901 9.73049 15.0011 9.66276 15.0847 9.57716C15.1683 9.49155 15.2336 9.38985 15.2767 9.27823C15.3198 9.16661 15.3398 9.04739 15.3354 8.92782ZM26.3846 9.76782C26.3647 7.87693 25.5966 6.07091 24.2484 4.74492C22.9003 3.41893 21.0817 2.68094 19.1908 2.69244H8.80923C6.91827 2.68094 5.09974 3.41893 3.75155 4.74492C2.40336 6.07091 1.63528 7.87693 1.61538 9.76782V18.2324C1.63528 20.1233 2.40336 21.9294 3.75155 23.2553C5.09974 24.5813 6.91827 25.3193 8.80923 25.3078H19.1908C21.0817 25.3193 22.9003 24.5813 24.2484 23.2553C25.5966 21.9294 26.3647 20.1233 26.3846 18.2324V17.8771H20.5154C19.4443 17.8771 18.4171 17.4516 17.6598 16.6942C16.9024 15.9369 16.4769 14.9097 16.4769 13.8386C16.4769 12.7675 16.9024 11.7403 17.6598 10.983C18.4171 10.2256 19.4443 9.80013 20.5154 9.80013H26.3846V9.76782Z"
        fill="#8870E6"
      />
      <path
        d="M20.5154 14.754C21.0209 14.754 21.4308 14.3441 21.4308 13.8386C21.4308 13.333 21.0209 12.9232 20.5154 12.9232C20.0098 12.9232 19.6 13.333 19.6 13.8386C19.6 14.3441 20.0098 14.754 20.5154 14.754Z"
        fill="#8870E6"
      />
    </svg>
  );
};

const AccountsFilledSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(AccountsFilledSVG);
