import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ color, size }) => {
  return (
    <svg
      width={size || 28}
      height={size || 28}
      viewBox="0 0 28 28"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40736 4.02428C8.26059 4.02428 8.13242 4.08129 8.04812 4.16275L5.337 6.78238L22.6631 6.78262L19.9513 4.16281C19.867 4.08135 19.7388 4.02428 19.592 4.02428H8.40736ZM24.0543 8.65309L3.94579 8.65281L3.94565 18.676C3.94561 21.6029 6.21862 23.9757 9.02254 23.9757H18.9774C21.7813 23.9757 24.0543 21.603 24.0543 18.6761V8.65309ZM6.83093 2.7901C7.26 2.37551 7.82811 2.15384 8.40736 2.15384H19.592C20.1712 2.15384 20.7393 2.37551 21.1684 2.7901L25.5587 7.03149C25.742 7.2085 25.8461 7.45728 25.8461 7.71788V18.6761C25.8461 22.636 22.7709 25.8461 18.9774 25.8461H9.02254C5.22901 25.8461 2.15375 22.636 2.15381 18.676L2.15396 7.71793C2.15396 7.45737 2.2581 7.20862 2.44128 7.03162L6.83093 2.7901ZM9.8935 10.7503C10.3883 10.7503 10.7894 11.169 10.7894 11.6856C10.7894 13.2936 12.1603 14.7179 13.9997 14.7179C15.8391 14.7179 17.2099 13.2936 17.2099 11.6856C17.2099 11.169 17.611 10.7503 18.1058 10.7503C18.6006 10.7503 19.0018 11.169 19.0018 11.6856C19.0018 14.46 16.6958 16.5884 13.9997 16.5884C11.3035 16.5884 8.99758 14.46 8.99758 11.6856C8.99758 11.169 9.39869 10.7503 9.8935 10.7503Z"
        fill="#A7AAB5"
      />
    </svg>
  );
};

const BudgetsOutlineSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(BudgetsOutlineSVG);
