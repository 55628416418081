import React from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ rotate, color }) => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={rotate ? `rotate(${rotate})` : ''}
    >
      <path
        d="M7 1.5L4 4.5L1 1.5"
        stroke={color || '#2E3E5F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SVGTableSortIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default SVGTableSortIcon;
