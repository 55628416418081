import React from 'react';
import MyCard from '../../../components/myCard/MyCard';
import { Space } from 'antd';
import LogoSVG from '../../../icons/LogoSVG';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import ReactCodeInput from 'react-verification-code-input';
import { useNavigate } from 'react-router-dom';
import UsersActions from '../../../actions/UsersActions';
import UtilNotify from '../../../utils/UtilNotify';
import { useState } from 'react';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import { useTranslation } from 'react-i18next';

const TwoStepVerification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setloading] = useState(false);

  const handleComplete = async (value) => {
    setloading(true);
    const result = await UsersActions.verify2Fa(value);

    if (!result.success) {
      setloading(false);
      UtilNotify.notifyErrorServer(result.errMsg);
      return;
    }

    navigate('/home', { replace: true });
  };

  return (
    <MyCard className="login-card two_factor_authentication">
      <Space direction="vertical" size={'large'} style={{ marginTop: 10 }}>
        <LogoSVG size={50} />
        <Space
          direction="vertical"
          size={'middle'}
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <MyTextTitle size={30}>{t('WORD_PLEASE_ENTER_2FA_CODE')}</MyTextTitle>
          <MyTextSecondary className="description">
            {t(
              'WORD_OPEN_THE_TWO_FACTOR_AUTHENTICATION_APP_ON_YOUR_DEVICE_TO_VIEW_YOUR_AUTHENTICATION_CODE_AND_VERIFY_YOUR_IDENTITY'
            )}
          </MyTextSecondary>
        </Space>
        <ReactCodeInput
          fields={6}
          type="number"
          onComplete={handleComplete}
          loading={loading}
          autoFocus={true}
          className={'code-input'}
        />
      </Space>
    </MyCard>
  );
};

export default TwoStepVerification;
