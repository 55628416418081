import { Divider } from 'antd';
import React from 'react';
import './MyDivider.scss';

const MyDivider = ({
  marginLeft,
  marginRight,
  color,
  style,
  type = 'vertical',
  height,
}) => {
  return (
    <Divider
      className="my-divider"
      type={type}
      style={{
        marginRight: marginRight || 10,
        marginLeft: marginLeft || 10,
        color: color || '#F1F1F1',
        height: height,
        ...style,
      }}
    />
  );
};

export default MyDivider;
