import React, { useState } from 'react';
import MyCard from '../../../components/myCard/MyCard';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import FormApp from '../../../components/formApp/FormApp';
import { useForm } from 'antd/es/form/Form';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import MySpace from '../../../components/mySpace/MySpace';
import MySegmentedForm from '../../../components/mySegmented/MySegmentedForm';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import SelectUploadFileDataType from '../../../components/selects/SelectUploadFileDataType';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BudgetActions from '../../../actions/BudgetActions';
import UtilNumber from '../../../utils/UtilNumber';
import UtilNotify from '../../../utils/UtilNotify';
import ApiStorage from '../../../redux/ApiStorage';
import SelectCurrencyMain from '../../../components/selects/SelectCurrencyMain';
import SelectNumberFormat from '../../../components/selects/SelectNumberFormat';
import SelectCurrencyPlacement from '../../../components/selects/SelectCurrencyPlacement';
import SelectDateFormat from '../../../components/selects/SelectDateFormat';
import UploadFiles from '../../../components/uploads/UploadFiles';
import MyDefaultPageLayout from '../../../components/myDefaultPageLayout/MyDefaultPageLayout';

const formName = 'create-budget-form';

const BudgetCreate = (props) => {
  const { isFirst } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = useForm();

  const [saving, setSaving] = useState(false);
  const [dataType, setDataType] = useState(BudgetActions.DATA_TYPE.DEFAULT);

  const isUpload = Boolean(dataType === BudgetActions.DATA_TYPE.UPLOAD);

  const generateDbName = () => {
    return `moneyscope_${UtilNumber.getNewUniqID()}`;
  };

  const onFinish = async (values) => {
    setSaving(true);
    let result = await BudgetActions.createBudget(values);
    if (result.success) {
      let resultSettings = await BudgetActions.getSettings();
      if (resultSettings.success) {
        //     ApiStorage.setCurrentDbSettings(resultSettings.data);
        navigate('/home');
      }
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
      form.setFieldsValue({ dbName: generateDbName() });
      setSaving(false);
    }
  };

  return (
    <MyDefaultPageLayout>
      <MyCard fullHeight style={{ width: isFirst ? '45%' : '100%' }}>
        <MyPageHeader
          title={t('WORD_NEW_BUDGET')}
          onBack={() => {
            if (isFirst) {
              ApiStorage.clearUser();
              navigate('/login');
            } else {
              navigate('/dbbudget/list');
            }
          }}
          style={{ marginBottom: 10 }}
        />
        <MySpace direction="vertical" size="middle" fullWidth>
          <FormApp
            name={formName}
            form={form}
            onFinish={onFinish}
            initialValues={{
              dbName: generateDbName(),
              dataType: dataType,
              settings: {
                idCurrency: 2,
                formatNumber: 7,
                currencyPlacement: 3,
                formatDate: 4,
              },
            }}
          >
            <InputTextFormItem
              name={['settings', 'displayName']}
              required
              label={t('WORD_BUDGET_NAME')}
              placeholder={t('WORD_BUDGET_NAME')}
            />
            <InputTextFormItem
              name="dbName"
              required
              hidden
              placeholder={t('WORD_DB_NAME')}
            />
            <SelectCurrencyMain
              name={['settings', 'idCurrency']}
              required
              label={t('WORD_CURRENCY')}
            />
            <SelectNumberFormat
              name={['settings', 'formatNumber']}
              required
              label={t('WORD_FORMAT_NUMBER')}
            />
            <SelectCurrencyPlacement
              name={['settings', 'currencyPlacement']}
              required
              label={t('WORD_CURRENCY_PLACEMENT')}
            />
            <SelectDateFormat
              name={['settings', 'formatDate']}
              required
              label={t('WORD_FORMAT_DATE')}
            />
            <MySegmentedForm
              name="dataType"
              options={[
                BudgetActions.DATA_TYPE.DEFAULT,
                BudgetActions.DATA_TYPE.BLANK,
                BudgetActions.DATA_TYPE.UPLOAD,
              ]}
              onChange={setDataType}
              label={t('WORD_DATA_TYPE')}
              required
            />
            {isUpload && (
              <>
                <SelectUploadFileDataType
                  name="fileType"
                  label="File Type"
                  required
                />
                <UploadFiles name="filePath" label={t('WORD_FILE')} required />
              </>
            )}
          </FormApp>
          <MyButtonMain
            loading={saving}
            form={formName}
            label={t('WORD_CREATE_NEW_BUDGET')}
            fullWidth
          />
        </MySpace>
      </MyCard>
    </MyDefaultPageLayout>
  );
};

export default BudgetCreate;
