import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || '28'}
      height={size || '28'}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4074 4.02429C8.26062 4.02429 8.13246 4.0813 8.04816 4.16276L5.33704 6.78239L22.6631 6.78263L19.9513 4.16281C19.867 4.08136 19.7388 4.02429 19.592 4.02429H8.4074ZM6.83096 2.79011C7.26003 2.37552 7.82815 2.15385 8.4074 2.15385H19.592C20.1713 2.15385 20.7394 2.37552 21.1685 2.79011L25.5588 7.0315C25.742 7.20851 25.8462 7.45729 25.8462 7.71788V18.6761C25.8462 22.636 22.7709 25.8462 18.9774 25.8462H9.02258C5.22904 25.8462 2.15379 22.636 2.15385 18.676L2.154 7.71794C2.154 7.45737 2.25814 7.20863 2.44132 7.03163L6.83096 2.79011ZM9.89354 10.7503C10.3883 10.7503 10.7895 11.1691 10.7895 11.6856C10.7895 13.2936 12.1603 14.7179 13.9997 14.7179C15.8391 14.7179 17.21 13.2936 17.21 11.6856C17.21 11.1691 17.6111 10.7503 18.1059 10.7503C18.6007 10.7503 19.0018 11.1691 19.0018 11.6856C19.0018 14.46 16.6959 16.5884 13.9997 16.5884C11.3036 16.5884 8.99762 14.46 8.99762 11.6856C8.99762 11.1691 9.39873 10.7503 9.89354 10.7503Z"
        fill="#8870E6"
      />
    </svg>
  );
};

const BudgetsFilledSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(BudgetsFilledSVG);
