import { Flex, Modal } from 'antd';
import React, { useMemo } from 'react';
import TransactionActions from '../../../actions/TransactionActions';
import MyTag from '../../../components/myTag/MyTag';
import { useTranslation } from 'react-i18next';
import MyDefaultDropDown from '../../../components/myDefaultDropDown/MyDefaultDropDown';
import UtilNotify from '../../../utils/UtilNotify';
import MyDefaultRangePicker from '../../../components/myDefaultRangePicker/MyDefaultRangePicker';
import TransactionFilterDrawer from '../TransactionFilterDrawer';
import MySearchInputFormItem from '../../../components/myInputForm/MySearchInputFormItem';
import MyButtonTransparent from '../../../components/myButton/myButtonTransparent/MyButtonTransparent';

const TransactionHeaderFilters = ({
  filter,
  setfilter,
  openFilters,
  selectedRowKeys,
  onChangeApproved,
  onDeleteTransaction,
  filtersDrawerRef,
  drawerLoading,
  tableRef,
  inputSearchRef,
  defaultFilter,
}) => {
  const { t } = useTranslation();

  const selectedMenuItems = useMemo(
    () => [
      {
        label: t('WORD_APPROVED'),
        key: 'cleared',
        onClick: async () => {
          const result = await TransactionActions.makeClearedMultiple(
            selectedRowKeys
          );
          if (result.success) {
            onChangeApproved &&
              onChangeApproved(
                result.data.map((tr) => tr.idTransaction),
                1
              );
          } else {
            UtilNotify.notifyErrorServer(result.errMsg);
          }
        },
      },
      {
        label: t('WORD_NOT_APPROVED'),
        key: 'notcleared',
        onClick: async () => {
          const result = await TransactionActions.makeUnclearedMultiple(
            selectedRowKeys
          );
          if (result.success) {
            onChangeApproved &&
              onChangeApproved(
                result.data.map((tr) => tr.idTransaction),
                0
              );
          } else {
            UtilNotify.notifyErrorServer(result.errMsg);
          }
        },
      },
      {
        label: t('WORD_DELETE'),
        key: 'delete',
        danger: true,
        onClick: async () => {
          Modal.confirm({
            title: 'Are you sure that you want to delete this transaction?',
            async onOk() {
              const result = await TransactionActions.deleteMultiple(
                selectedRowKeys
              );
              if (result.success) {
                onDeleteTransaction &&
                  onDeleteTransaction(
                    result.data.map((tr) => tr.idTransaction)
                  );
              } else {
                UtilNotify.notifyErrorServer(result.errMsg);
              }
            },
            okText: 'Yes',
            cancelText: 'No',
            okButtonProps: {
              type: 'primary',
              className: 'my-button',
            },
            cancelButtonProps: {
              type: 'cancel',
              className: 'my-button my-button-secondary-filled',
            },
          });
        },
      },
    ],
    [selectedRowKeys, t, onChangeApproved, onDeleteTransaction]
  );

  const statusMenuItems = useMemo(
    () => [
      {
        label: t('WORD_APPROVED'),
        key: 'cleared',
        onClick: async () => {
          if (!filter?.approved) {
            setfilter((ov) => {
              return { ...ov, approved: true, page: 0 };
            });
          }
        },
      },
      {
        label: t('WORD_NOT_APPROVED'),
        key: 'notcleared',
        onClick: async () => {
          if (!filter?.hasOwnProperty('approved') || filter?.approved) {
            setfilter((ov) => {
              return { ...ov, approved: false, page: 0 };
            });
          }
        },
      },
    ],
    [t, filter, setfilter]
  );

  const selectedMenuProps = {
    items: selectedMenuItems,
    onClick: selectedMenuItems.onClick,
  };

  const statusMenuProps = {
    items: statusMenuItems,
    onClick: statusMenuItems.onClick,
  };

  return (
    <Flex align="center" gap={10}>
      {selectedRowKeys.length > 0 && (
        <MyDefaultDropDown
          menuProps={selectedMenuProps}
          title={`Selected ${selectedRowKeys.length} ${
            selectedRowKeys.length === 1 ? 'item' : 'items'
          }`}
        />
      )}
      {Boolean(!selectedRowKeys.length) && (
        <React.Fragment>
          <MySearchInputFormItem
            ref={inputSearchRef}
            defaultValue={filter?.search}
            placeholder="Categories, Payees and Notes"
            onPressEnter={(value) => {
              setfilter((ov) =>
                Object.assign({}, ov, { search: value, page: 0 })
              );
            }}
            onClear={() => {
              setfilter((ov) => Object.assign({}, ov, { search: '', page: 0 }));
            }}
          />
          <MyDefaultRangePicker
            dates={filter?.DateRange}
            onDateChange={(value) => {
              if (!value) {
                setfilter((ov) => {
                  delete ov.dateFrom;
                  delete ov.dateTo;
                  delete ov.tag__DateRange;
                  delete ov.DateRange;
                  return { ...ov, page: 0 };
                });
              } else {
                setfilter((ov) => {
                  return {
                    ...ov,
                    dateFrom: value?.[0].format('YYYY-MM-DD'),
                    dateTo: value?.[1].format('YYYY-MM-DD'),
                    page: 0,
                    DateRange: value,
                  };
                });
              }
            }}
          />
          <MyDefaultDropDown
            menuProps={statusMenuProps}
            title={`Status${
              filter?.hasOwnProperty('approved')
                ? `: ${t(
                    filter?.approved ? 'WORD_APPROVED' : 'WORD_NOT_APPROVED'
                  )}`
                : ''
            }`}
            inactive={!filter?.hasOwnProperty('approved')}
            onClose={
              filter?.hasOwnProperty('approved')
                ? () => {
                    setfilter((ov) => {
                      delete ov.approved;
                      return { ...ov, page: 0 };
                    });
                  }
                : null
            }
          />
          {Boolean(filter?.idCategory?.length) && (
            <MyTag
              title={'Category'}
              onClose={() => {
                setfilter((ov) => {
                  delete ov.idCategory;
                  return { ...ov, page: 0 };
                });
              }}
              onClick={openFilters}
            />
          )}
          {Boolean(filter?.idAccount?.length) && (
            <MyTag
              title={'Account'}
              onClose={() => {
                setfilter((ov) => {
                  delete ov.idAccount;
                  return { ...ov, page: 0 };
                });
              }}
              onClick={openFilters}
            />
          )}
          {Boolean(filter?.idPayee?.length) && (
            <MyTag
              title={'Payee'}
              onClose={() => {
                setfilter((ov) => {
                  delete ov.idPayee;
                  return { ...ov, page: 0 };
                });
              }}
              onClick={openFilters}
            />
          )}
        </React.Fragment>
      )}
      {Boolean(!selectedRowKeys.length) && (
        <React.Fragment>
          <TransactionFilterDrawer
            ref={filtersDrawerRef}
            loading={drawerLoading}
            filter={filter}
            onChange={(value) =>
              setfilter((ov) => Object.assign({}, ov, value, { page: 0 }))
            }
            onReset={() => {
              tableRef?.current?.scrollTop();
              inputSearchRef?.current?.clearInput();
              setfilter(defaultFilter);
            }}
          />
          <MyButtonTransparent
            label={'Reset Filters'}
            onClick={() => {
              setfilter(defaultFilter);
            }}
          />
        </React.Fragment>
      )}
    </Flex>
  );
};

export default TransactionHeaderFilters;
