import React, { forwardRef } from 'react';
import InputText from './InputText';
import SVGSearchIcon from '../../icons/SVGSearchIcon';

const MySearchInputFormItem = forwardRef(
  ({ defaultValue, placeholder, onPressEnter, onClear }, ref) => {
    return (
      <InputText
        className={'search'}
        ref={ref}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onPressEnter={onPressEnter}
        onClear={onClear}
        prefix={<SVGSearchIcon />}
      />
    );
  }
);

export default MySearchInputFormItem;
