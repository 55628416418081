import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ color, rotate, size }) => {
  return (
    <svg
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={rotate ? `rotate(${rotate})` : ''}
      width={size || 13}
      height={size || 8}
    >
      <path
        fill={color || '#8870E6'}
        d="M6.86221e-05 0.970802C-0.00147824 1.09639 0.0232849 1.22094 0.0728111 1.33669C0.122338 1.45243 0.195565 1.55689 0.287925 1.64354L5.69032 6.95964C5.79415 7.06866 5.91915 7.15605 6.05791 7.21666C6.19668 7.27726 6.34638 7.30984 6.49817 7.31246C6.65059 7.3137 6.80152 7.28287 6.94084 7.22202C7.08017 7.16117 7.20467 7.07171 7.30602 6.95964L12.7121 1.64354C12.8039 1.55643 12.8767 1.45188 12.9262 1.33624C12.9757 1.2206 13.0008 1.09628 13 0.970802C12.9995 0.84207 12.9731 0.714707 12.9223 0.596072C12.8716 0.477437 12.7974 0.369883 12.7042 0.279622C12.6109 0.189362 12.5004 0.118186 12.3791 0.0702055C12.2577 0.0222252 12.1279 -0.00160746 11.9971 8.40834e-05C11.859 0.000430716 11.7223 0.0275718 11.5948 0.0799559C11.4674 0.13234 11.3517 0.208939 11.2543 0.305376L6.49631 5.0182L1.73834 0.305375C1.64097 0.208939 1.52525 0.132339 1.39778 0.0799554C1.27032 0.0275714 1.13362 0.000430248 0.995491 8.36025e-05C0.865346 -0.00063838 0.736334 0.0239106 0.615838 0.0723256C0.495342 0.120741 0.385729 0.192071 0.29327 0.282235C0.200812 0.372398 0.127325 0.479625 0.0770139 0.597778C0.0267028 0.715931 0.000555002 0.84269 6.86221e-05 0.970802Z"
      />
    </svg>
  );
};

const ArrowSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ArrowSVG);
