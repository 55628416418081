import React from 'react';
import Div from '../div/Div';
import { Flex } from 'antd';
import './MyDefaultPageHeader.scss';

const MyDefaultPageHeader = ({ headerProps }) => {
  return (
    <Div className={`my_default_page_header`}>
      <Flex gap={10} align="center" className={'left'}>
        {headerProps?.left && headerProps.left}
      </Flex>
      <Flex gap={10} align="center" className={'right'}>
        {headerProps?.right && headerProps.right}
      </Flex>
    </Div>
  );
};

export default MyDefaultPageHeader;
