import React, { useEffect, useState } from 'react';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import FormApp from '../../../components/formApp/FormApp';
import MyCard from '../../../components/myCard/MyCard';
import { useForm } from 'antd/es/form/Form';
import InputEmailFormItem from '../../../components/myInputForm/InputEmailFormItem';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyButtonSecondary from '../../../components/myButton/MyButtonSecondary';
import MyText from '../../../components/myText/MyText';
import MyButtonDelete from '../../../components/myButton/MyButtonDelete';
import SelectTheme from '../../../components/selects/SelectTheme';
import { useSelector } from 'react-redux';
import ApiStorage from '../../../redux/ApiStorage';
import MySpace from '../../../components/mySpace/MySpace';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import UtilNotify from '../../../utils/UtilNotify';
import UsersActions from '../../../actions/UsersActions';
import IconAvatarForm from '../../../components/uploads/uploadAvatar/IconAvatarForm';
import MyDefaultPageLayout from '../../../components/myDefaultPageLayout/MyDefaultPageLayout';

const formName = 'user_settings_form';

const UserSettings = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [form] = useForm();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => {
    return state.currentUser;
  });

  const selectedTheme = useSelector((state) => {
    return state.selectedTheme;
  });

  const [saving, setSaving] = useState(false);
  const [icon, setIcon] = useState('');

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue(currentUser);
      setIcon(currentUser.icon);
    }
  }, [currentUser, form]);

  const onFinish = async (values) => {
    setSaving(true);
    values.icon = icon;
    let result = await UsersActions.changeAccountSettings(values);
    if (isMounted()) {
      if (result.success) {
        UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setSaving(false);
    }
  };

  const handleDeleteAccount = async () => {
    setSaving(true);
    let result = await UsersActions.deleteAccount();
    if (isMounted()) {
      if (result.success) {
        UtilNotify.notifywarning(t('WORD_DELETED_SUCCESSFULY'));
        ApiStorage.clearUser();
        navigate(`/login`);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setSaving(false);
    }
  };

  return (
    <MyDefaultPageLayout>
      <MyCard>
        <MyPageHeader title="Edit profile" style={{ marginBottom: 10 }} />
        <FormApp name={formName} form={form} onFinish={onFinish}>
          <MySpace direction="vertical" fullWidth>
            <IconAvatarForm
              name="icon"
              label={t('WORD_ICON')}
              icon={icon}
              onChange={(path) => {
                setIcon(path);
              }}
              style={{ cursor: 'pointer' }}
              onClear={() => {
                setIcon('');
              }}
            />
            <InputEmailFormItem
              name="email"
              label={t('WORD_EMAIL')}
              readOnly
              required
            />
            <InputTextFormItem
              name="displayName"
              label={t('WORD_NAME')}
              required
            />
            <MyButtonMain
              fullWidth
              label={t('WORD_SAVE')}
              form={formName}
              htmlType="submit"
              loading={saving}
            />
          </MySpace>
        </FormApp>
        <MySpace style={{ marginTop: 25 }} direction="horizontal">
          <MySpace direction="vertical" style={{ width: '80%' }}>
            <MyTextTitle size={20}>
              {t('WORD_TWO_STEP_VERIFICATION')}
            </MyTextTitle>
            <MyText>{t('WORD_FA_SUBTITLE')}</MyText>
          </MySpace>
          {currentUser?.is2FAenabled ? (
            <MyButtonSecondary
              label={t('WORD_TURN_OFF')}
              onClick={() => navigate('/users/turn_off_2_step_verification')}
            />
          ) : (
            <MyButtonSecondary
              label={t('WORD_SET_UP')}
              onClick={() => navigate('/users/set_up_2_step_verification')}
            />
          )}
        </MySpace>
        <MySpace
          style={{
            marginTop: 40,
          }}
          fullWidth
          spaceBetween
          direction="horizontal"
        >
          <MySpace direction="vertical" fullWidth>
            <MyTextTitle size={20}>{t('WORD_APPEARANCE')}</MyTextTitle>
            <MyText>{t('WORD_APPEARANCE_SUBTITLE')}</MyText>
          </MySpace>
          <SelectTheme
            width={150}
            onChange={(e) => ApiStorage.setSelectedTheme(e)}
            value={selectedTheme}
          />
        </MySpace>
        <MySpace
          style={{
            marginTop: 40,
          }}
          fullWidth
          spaceBetween
          direction="horizontal"
        >
          <MySpace direction="vertical" style={{ width: '80%' }}>
            <MyTextTitle size={20}>{t('WORD_CHANGE_PASSWORD')}</MyTextTitle>
            <MyText>{t('WORD_CHANGE_PASSWORD_SUBTITLE')}</MyText>
          </MySpace>
          <MyButtonSecondary
            loading={saving}
            onClick={() => navigate('/users/change_password')}
            label={t('WORD_CHANGE_PASSWORD')}
          />
        </MySpace>
        <MySpace
          style={{
            marginTop: 40,
            alignItems: 'flex-start',
          }}
          fullWidth
          spaceBetween
          direction="horizontal"
        >
          <MySpace direction="vertical" style={{ width: '80%' }}>
            <MyTextTitle size={20}>{t('WORD_DELETE_ACCOUNT')}</MyTextTitle>
            <MySpace direction="vertical" size="large">
              <MyText>{t('WORD_DELET_DESCRIPTION_1')}</MyText>
              <MyText>{t('WORD_DELET_DESCRIPTION_2')}</MyText>
              <MyText>{t('WORD_DELET_DESCRIPTION_3')}</MyText>
            </MySpace>
          </MySpace>
          <MyButtonDelete
            loading={saving}
            onClick={handleDeleteAccount}
            label={t('WORD_DELETE_ACCOUNT')}
          />
        </MySpace>
      </MyCard>
    </MyDefaultPageLayout>
  );
};

export default UserSettings;
