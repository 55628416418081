import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const MyText = ({
  children,
  size,
  bold,
  className,
  style,
  onClick,
  fontWeight,
  color,
  lineHeight,
}) => {
  return (
    <Text
      style={{
        fontSize: size,
        fontWeight: fontWeight,
        color: color,
        lineHeight: lineHeight,
        ...style,
      }}
      strong={bold || false}
      className={className}
      onClick={onClick}
    >
      {children}
    </Text>
  );
};

export default MyText;
