import React from 'react';
import MySpace from '../../components/mySpace/MySpace';
import MyTextTitle from '../../components/myText/MyTextTitle';
import MyLink from '../../components/myLink/MyLink';
import ArrowSVG from '../../icons/ArrowSVG';
import MyTextSecondary from '../../components/myText/MyTextSecondary';
import MyStatistic from '../../components/myStatistic/MyStatistic';
import UtilNumber from '../../utils/UtilNumber';
import { useTranslation } from 'react-i18next';
import { primaryColor, successColor } from '../../theme/themeLightConfig';
import MyProgressBar from '../../components/myProgressBar/MyProgressBar';
import SuccessArrowSVG from '../../icons/SuccessArrowSVG';
import MyText from '../../components/myText/MyText';
import MyDivider from '../../components/myDivider/MyDivider';
import MyCard from '../../components/myCard/MyCard';

const ActivityBudgetOverviewHeader = (props) => {
  const { totals } = props;
  const { t } = useTranslation();

  return (
    <MyCard>
      <MySpace spaceBetween fullWidth>
        <MyTextTitle size={20}>{t('WORD_BUDGET_OVERVIEW')}</MyTextTitle>
        <MyLink path="/category/list" className="arrow-bg-always">
          <ArrowSVG color="#a7aab5" />
        </MyLink>
      </MySpace>
      <MyDivider />
      <MySpace fullWidth spaceBetween>
        <MySpace direction="vertical" size={0}>
          <MyTextSecondary size={12}>{t('WORD_BUDGETED')}</MyTextSecondary>
          <MyStatistic
            value={UtilNumber.formatDouble(totals.thisMonthBudgeted)}
            style={{
              fontSize: 15,
              fontWeight: 700,
              color: primaryColor,
            }}
            className="stat-number"
          />
        </MySpace>
        <MySpace direction="vertical" size={0}>
          <MyTextSecondary size={12}>{t('WORD_SPENT')}</MyTextSecondary>
          <MyStatistic
            value={UtilNumber.formatDouble(totals.thisMonthSpent)}
            style={{
              fontSize: 15,
              fontWeight: 700,
              color: primaryColor,
            }}
            className="stat-number"
          />
        </MySpace>
        <MySpace direction="vertical" size={0}>
          <MyTextSecondary size={12}>{t('WORD_LEFT_CAP')}</MyTextSecondary>
          <MyStatistic
            value={UtilNumber.formatDouble(totals.availableThisPure)}
            style={{
              fontSize: 15,
              fontWeight: 700,
              //  color: primaryColor,
            }}
            className="stat-number"
          />
        </MySpace>
      </MySpace>
      <MyProgressBar
        shouldBePerc={totals?.shouldBePerc}
        totalSpent={totals?.thisMonthSpent}
        totalBudgeted={totals?.thisMonthBudgeted}
        color={primaryColor}
      />
      <MySpace>
        <SuccessArrowSVG />
        <MyText style={{ color: successColor }}>
          {t('WORD_YOU_ARE_ON_TRACK!')}
        </MyText>
      </MySpace>
      <MyDivider />
    </MyCard>
  );
};

export default ActivityBudgetOverviewHeader;
