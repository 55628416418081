import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 28}
      height={size || 28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5845 3.12465C19.5845 6.04801 21.9579 8.41786 24.8855 8.41786C25.2079 8.41628 25.5294 8.38533 25.8462 8.3254V18.8194C25.8462 23.2342 23.242 25.8462 18.8206 25.8462H9.19094C4.75803 25.8462 2.15385 23.2342 2.15385 18.8194V9.20375C2.15385 4.78889 4.75803 2.15385 9.19094 2.15385H19.6771C19.6149 2.4737 19.5839 2.79882 19.5845 3.12465ZM7.88755 18.1019C8.04973 18.24 8.24172 18.3077 8.43252 18.3077C8.70083 18.3077 8.96556 18.1736 9.14205 17.9187L12.1603 13.5522L15.5207 16.4915C15.7091 16.6548 15.9452 16.7318 16.1837 16.696C16.4198 16.6641 16.6345 16.5274 16.78 16.3176L20.274 11.2993C20.5769 10.8652 20.5053 10.2399 20.1142 9.90265C19.723 9.56279 19.1626 9.64377 18.8597 10.0806L15.9178 14.3063L12.555 11.3657C12.3677 11.1997 12.1316 11.1254 11.8907 11.1599C11.6546 11.1931 11.4388 11.3298 11.2933 11.5409L7.72417 16.7053C7.42247 17.1407 7.49641 17.766 7.88755 18.1019Z"
        fill="#8870E6"
      />
      <circle
        opacity="0.4"
        cx="24.7692"
        cy="3.23077"
        r="3.23077"
        fill="#8870E6"
      />
    </svg>
  );
};

const ActivityFilledSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ActivityFilledSVG);
