import React, { forwardRef, useImperativeHandle } from 'react';
import { Drawer } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyButtonSecondary from '../myButton/MyButtonSecondary';
import MySpace from '../mySpace/MySpace';
import MyButtonMain from '../myButton/MyButtonMain';
import MyButtonSecondaryFilled from '../myButton/MyButtonSecondaryFilled';

const MyDrawer = forwardRef((props, ref) => {
  const {
    children,
    title,
    label,
    formName,
    icon,
    loading,
    forceRender,
    destroyOnClose,
  } = props;
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    showDrawer: showDrawer,
    closeDrawer: closeDrawer,
  }));

  return (
    <>
      <MyButtonSecondaryFilled
        onClick={showDrawer}
        label={label}
        icon={icon}
        loading={loading}
        className={'inactive'}
      />
      <Drawer
        title={title}
        placement="right"
        onClose={closeDrawer}
        open={visible}
        forceRender={forceRender}
        destroyOnClose={destroyOnClose}
        getContainer={false}
        extra={
          <MySpace>
            {/* <Button onClick={closeDrawer}>Cancel</Button> */}
            {/* <Button
                            type="primary"
                            htmlType='submit'
                            form={formName}
                            onClick={closeDrawer}
                        >
                            Apply
                        </Button> */}
            <MyButtonSecondary onClick={closeDrawer} label={t('WORD_CANCEL')} />
            <MyButtonMain
              type="primary"
              htmlType="submit"
              form={formName}
              onClick={closeDrawer}
              label={t('WORD_APPLY')}
            />
          </MySpace>
        }
      >
        {children}
      </Drawer>
    </>
  );
});

export default MyDrawer;
