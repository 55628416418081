import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import MyDefaultPageHeader from '../myDefaultPageHeader/MyDefaultPageHeader';
import Div from '../div/Div';
import './MyDefaultPageLayout.scss';

const MyDefaultPageLayout = forwardRef(
  (
    { children, helmetTitle, content, disableBottomPadding, headerProps },
    ref
  ) => {
    return (
      <Div
        ref={ref}
        className={`my_default_page_layout ${
          disableBottomPadding ? 'disable_bottom_padding' : ''
        }`}
      >
        {Boolean(helmetTitle) && (
          <Helmet>
            <title></title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content={content} />
            <meta
              property="og:image"
              content="public/favicon/mstile-150x150.png"
            />
          </Helmet>
        )}
        {headerProps && <MyDefaultPageHeader headerProps={headerProps} />}
        {children}
      </Div>
    );
  }
);

export default MyDefaultPageLayout;
