import React, { useCallback, useEffect, useRef, useState } from 'react';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import { useSelector } from 'react-redux';
import AccountActions from '../../../actions/AccountActions';
import UtilNotify from '../../../utils/UtilNotify';
import ApiStorage from '../../../redux/ApiStorage';
// import AccountListItemMain from './AccountListItemMain';
import MyList from '../../../components/myList/MyList';
import AccountListItem from './AccountListItem';
import LoadingIconApp from '../../../components/loadingIconApp/LoadingIconApp';
import MyCollapse from '../../../components/myCollapse/MyCollapse';
import MySpace from '../../../components/mySpace/MySpace';
import MyText from '../../../components/myText/MyText';
import MyTextSecondary from '../../../components/myText/MyTextSecondary';
import MyCard from '../../../components/myCard/MyCard';
import { successColor, errorColor } from '../../../theme/themeLightConfig';
import AccountEditModal from '../accountEdit/AccountEditModal';
import MyStatistic from '../../../components/myStatistic/MyStatistic';
import PlusBtnSVG from '../../../icons/PlusBtnSVG';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MySpaceColumn from '../../../components/mySpace/MySpaceColumn';
import UtilNumber from '../../../utils/UtilNumber';
import MyTextTitle from '../../../components/myText/MyTextTitle';
import MyDefaultPageLayout from '../../../components/myDefaultPageLayout/MyDefaultPageLayout';
import './Account.scss';

const AccountList = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const canEdit = useSelector((state) => state.canEdit);

  const budgetName = useSelector((state) => {
    return state.currentDbSettings?.displayName || '';
  });

  const [listAccounts, setlistAccounts] = useState([]);
  const [hoveredAccount, sethoveredAccount] = useState('');
  const [loading, setloading] = useState(false);
  const [totalAmount, settotalAmount] = useState(0);

  const refEditModal = useRef();

  const fill = useCallback(async () => {
    setloading(true);
    let result = await AccountActions.fillPaginationList({
      search: '',
      pagination: {
        page: 0,
        perPage: 3000,
      },
      filter: {},
    });
    if (isMounted()) {
      if (result.success) {
        let list = [];
        let total = 0;
        for (const main of AccountActions.defaultAccountTypes) {
          let obj = { ...main };
          obj.options = result.data.filter((it) => {
            return (
              it.idType.toString().startsWith(obj.idGroup) &&
              it.status === AccountActions.STATUS.ACTIVE
            );
          });
          if (obj.options.length) {
            const totalAccountValue = obj.options.reduce(
              (previousValue, currentValue) =>
                previousValue + (currentValue.currentBalance || 0),
              0
            );
            obj.currentBalance = totalAccountValue;
            total += totalAccountValue;
            list.push(obj);
          }
        }
        let hidden = {
          idGroup: -1,
          idType: -1,
          idKey: '-1',
          accTyName: `${t('WORD_CLOSED')}`,
          options: result.data.filter((it) => {
            return it.status === AccountActions.STATUS.CLOSED;
          }),
        };
        if (hidden.options.length) {
          const totalAccountValue = hidden.options.reduce(
            (previousValue, currentValue) =>
              previousValue + (currentValue.currentBalance || 0),
            0
          );
          hidden.currentBalance = totalAccountValue;
          total += totalAccountValue;
          list.push(hidden);
        }
        setlistAccounts(list);
        settotalAmount(total);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    }
  }, [isMounted, t]);

  useEffect(() => {
    fill();
  }, [fill]);

  useEffect(() => {
    ApiStorage.setOnAccountUpdated((data) => {
      let list = [];
      for (const acc of data) {
        list = listAccounts.map((main) => {
          let find = main.options.find((ac) => {
            return acc.idKey === ac.idKey;
          });
          if (find) {
            main.options = main.options.map((ac) => {
              if (acc.idKey === ac.idKey) {
                Object.assign(ac, acc);
              }
              return ac;
            });
          } else {
            main.options.push(acc);
          }

          main.currentBalance = main.options.reduce(
            (previousValue, currentValue) =>
              previousValue + (currentValue.currentBalance || 0),
            0
          );

          return main;
        });
      }
      setlistAccounts(list);
    });

    return () => {
      ApiStorage.setOnAccountUpdated(null);
    };
  }, [listAccounts]);

  const getItem = (main) => {
    return {
      key: main.idType,
      label: (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            // alignItems: 'center',
            // backgroundColor: 'red',
          }}
        >
          <MyText bold>
            {main?.idGroup === 1
              ? t('WORD_BUDGET_ACCOUNTS_TITLE')
              : main?.accTyName}
          </MyText>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'right',
            }}
          >
            <MyTextSecondary>{t('WORD_BALANCE')}</MyTextSecondary>
            <MyStatistic
              style={{
                fontSize: 14,
                color: successColor,
                fontWeight: 700,
              }}
              value={UtilNumber.formatDouble(main?.currentBalance)}
            />
          </div>
        </div>
      ),
      children: (
        <MyList
          onMouseLeave={() => sethoveredAccount('')}
          data={main.options}
          renderItem={(item) => (
            <AccountListItem
              item={item}
              onClickEdit={(acc) => refEditModal.current.edit(acc.idKey)}
              onHover={sethoveredAccount}
              canEdit={canEdit}
              hoveredKey={hoveredAccount}
            />
          )}
          rowKey={(it) => it.idKey}
        />
      ),
    };
  };

  const handleOnSave = () => {
    fill();
  };

  return (
    <MyDefaultPageLayout
      helmetTitle={`${t('WORD_ACCOUNTS')} | ${budgetName} | ${t(
        'WORD_MONEYSCOPE'
      )} `}
      content={
        `${t('WORD_ACCOUNTS')}` | `${budgetName}` | `${t('WORD_MONEYSCOPE')}`
      }
    >
      <MySpace direction="vertical" size="middle" fullWidth>
        <MyPageHeader
          title={t('WORD_ACCOUNTS')}
          extraRight={
            <MyButtonMain
              label={t('WORD_ADD_ACCOUNT')}
              icon={<PlusBtnSVG isMainButtonIcon />}
              onClick={() => refEditModal.current.edit('')}
            />
          }
        />
        <div>
          {loading ? (
            <LoadingIconApp />
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 10,
              }}
            >
              <MySpaceColumn fullWidth style={{ marginBottom: 15 }}>
                {listAccounts?.map((main, i) => {
                  return (
                    <MyCard className="account-list-card" key={i}>
                      <MyCollapse
                        items={[getItem(main)]}
                        // style={{ marginTop: 20 }}
                        defaultActiveKey={listAccounts
                          .filter((oo) => oo.idGroup !== -1)
                          .map((it) => it.idType)}
                      />
                    </MyCard>
                  );
                })}
              </MySpaceColumn>
              <MyCard className="account-right-card">
                <MyTextTitle>{t('WORD_TOTAL')}</MyTextTitle>
                <MyStatistic value={UtilNumber.formatDouble(totalAmount)} />
                <MySpaceColumn fullWidth>
                  <MyStatistic
                    style={{ color: errorColor, fontSize: 14 }}
                    suffix={'%'}
                    value={'-142.3k'}
                  />
                  <MyTextSecondary>
                    {t('WORD_NET_WORTH_CHANGE_FROM_LAST_MONTH')}
                  </MyTextSecondary>
                </MySpaceColumn>
              </MyCard>
            </div>
          )}
          <AccountEditModal ref={refEditModal} onChange={handleOnSave} />
        </div>
      </MySpace>
    </MyDefaultPageLayout>
  );
};

export default AccountList;
