import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Input } from 'antd';
import './MyInputForm.scss';

const InputText = (
  {
    className,
    label,
    placeholder,
    readOnly,
    allowClear = true,
    onPressEnter,
    onChange,
    value,
    onClear,
    style,
    defaultValue,
    prefix,
  },
  ref
) => {
  const [inputValue, setInputValue] = useState(value || ''); // Initialize inputValue with the provided value or an empty string

  const clearInput = () => {
    setInputValue(''); // Clear the input value by updating state
    onClear && onClear(); // Trigger the onClear callback if provided
  };

  // Attach the ref to the Input component
  useImperativeHandle(ref, () => ({
    clearInput: () => clearInput(),
  }));

  return (
    <Input
      className={`my-input ${className || ''}`}
      style={style}
      value={inputValue}
      readOnly={readOnly}
      placeholder={placeholder || label || ''}
      label={label}
      autoComplete={'nope'}
      defaultValue={defaultValue}
      allowClear={allowClear}
      prefix={prefix}
      onChange={(e) => {
        setInputValue(e.target.value); // Update inputValue state
        onChange && onChange(e.target.value);
        if (!e.target.value) onClear && onClear();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onPressEnter && onPressEnter(e.target.value);
        }
      }}
    />
  );
};

export default forwardRef(InputText);
