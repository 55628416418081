import { Flex } from 'antd';
import React from 'react';
import SVGTableSortIcon from '../../icons/SVGTableSortIcon';
import colors from '../../Color.scss';

const MyDefaultSortArrows = ({ sortOrder }) => {
  return (
    <Flex vertical gap={4}>
      <SVGTableSortIcon
        rotate={'-180'}
        color={
          sortOrder.sortOrder === 'ascend'
            ? colors.bluishColor
            : colors.secondaryTextColor
        }
      />
      <SVGTableSortIcon
        color={
          sortOrder.sortOrder === 'descend'
            ? colors.bluishColor
            : colors.secondaryTextColor
        }
      />
    </Flex>
  );
};

export default MyDefaultSortArrows;
