import React from 'react';

const MyDefaultIconImage = ({ url }) => {
  return (
    <div style={{ width: 36, height: 36 }}>
      <img style={{ width: 36, height: 36 }} src={url} alt="categoryIcon" />
    </div>
  );
};

export default MyDefaultIconImage;
