import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.2" width="24" height="24" rx="12" fill="#8870E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2486 8.96528C16.5838 8.63008 16.5838 8.08661 16.2486 7.7514C15.9134 7.4162 15.3699 7.4162 15.0347 7.7514L12.0005 10.7856L8.96635 7.75151C8.63114 7.41631 8.08767 7.41631 7.75247 7.75151C7.41726 8.08672 7.41726 8.63019 7.75247 8.96539L10.7866 11.9995L7.7514 15.0347C7.4162 15.3699 7.4162 15.9134 7.7514 16.2486C8.08661 16.5838 8.63008 16.5838 8.96528 16.2486L12.0005 13.2134L15.0357 16.2487C15.3709 16.5839 15.9144 16.5839 16.2496 16.2487C16.5848 15.9135 16.5848 15.37 16.2496 15.0348L13.2143 11.9995L16.2486 8.96528Z"
        fill="#8870E6"
      />
    </svg>
  );
};

const SVGRemoveFilterIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGRemoveFilterIcon);
