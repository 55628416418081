import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 28}
      height={size || 28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.76923 8.02716C3.76922 4.78342 6.36776 2.15385 9.57321 2.15385H18.4268C21.6322 2.15385 24.2308 4.78342 24.2308 8.02715V24.9502C24.2308 25.2837 24.0477 25.5896 23.7557 25.7442C23.4636 25.8987 23.1109 25.8763 22.8403 25.686L20.2303 23.8511L17.6203 25.686C17.3166 25.8995 16.9137 25.8995 16.61 25.686L14 23.8511L11.39 25.686C11.0863 25.8995 10.6835 25.8995 10.3797 25.686L7.76975 23.8511L5.15977 25.686C4.88911 25.8763 4.53641 25.8987 4.24437 25.7442C3.95232 25.5896 3.76927 25.2837 3.76927 24.9502L3.76923 8.02716ZM8.08253 8.88989C8.08253 8.39509 8.47891 7.99397 8.96788 7.99397H19.0322C19.5211 7.99397 19.9175 8.39509 19.9175 8.88989C19.9175 9.3847 19.5211 9.78582 19.0322 9.78582H8.96788C8.47891 9.78582 8.08253 9.3847 8.08253 8.88989ZM8.08253 14.73C8.08253 14.2352 8.47891 13.8341 8.96788 13.8341H19.0322C19.5211 13.8341 19.9175 14.2352 19.9175 14.73C19.9175 15.2248 19.5211 15.6259 19.0322 15.6259H8.96788C8.47891 15.6259 8.08253 15.2248 8.08253 14.73Z"
        fill="#8870E6"
      />
    </svg>
  );
};

const TransactionsFilledSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(TransactionsFilledSVG);
