import { Flex } from 'antd';
import React from 'react';
import colors from '../../../Color.scss';
import TransactionActions from '../../../actions/TransactionActions';
import TableHeaderComponentItem from './TableHeaderComponentItem';
import './TableHeaderComponent.scss';

const TableHeaderComponent = ({ filter, setfilter, totals }) => {
  const items = [
    {
      color: colors.successColor,
      title: 'Inflow',
      type: TransactionActions.TYPE.INCOME,
      value: totals?.valueIncome,
    },
    {
      color: colors.outflowDarkColor,
      title: 'Outflow',
      type: TransactionActions.TYPE.EXPENSE,
      value: totals?.valueExpense,
    },
    {
      color: colors.primaryColor,
      title: 'Transfer',
      type: TransactionActions.TYPE.TRANSFER,
      value: totals?.valueTransfer,
    },
  ];

  return (
    <Flex className="transaction_list_table_header_wrapper" gap={1}>
      {items.map((item) => {
        return (
          <TableHeaderComponentItem
            key={item.title}
            color={item.color}
            title={item.title}
            type={item.type}
            value={item.value}
            filter={filter}
            setfilter={setfilter}
          />
        );
      })}
    </Flex>
  );
};

export default TableHeaderComponent;
