import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from '@utilityjs/use-is-mounted';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import { useNavigate } from 'react-router-dom';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MySpace from '../../../components/mySpace/MySpace';
import FormApp from '../../../components/formApp/FormApp';
import { useForm } from 'antd/es/form/Form';
import UploadFilesManuelStart from '../../../components/uploads/UploadFilesManuelStart';
import MyTable from '../../../components/myTable/MyTable';
import MyText from '../../../components/myText/MyText';
import UtilDate from '../../../utils/UtilDate';
import SelectAccount from '../../../components/selects/SelectAccount';
import UtilNumber from '../../../utils/UtilNumber';
import { Form, Space } from 'antd';
import InputTextFormItem from '../../../components/myInputForm/InputTextFormItem';
import MyButtonSecondary from '../../../components/myButton/MyButtonSecondary';
import SelectUploadFileBankDataType from './SelectUploadFileBankDataType';
import TransactionActions from '../../../actions/TransactionActions';
import UtilNotify from '../../../utils/UtilNotify';
import { SaveOutlined } from '@ant-design/icons';
import CustomImportModal from './CustomImportModal';
import MySwitchFormItem from '../../../components/mySwitchFormItem/MySwitchFormItem';
import HourglassSVG from '../../../icons/HourglassSVG';
import MySwitch from '../../../components/mySwitch/MySwitch';
import SelectPayeeValue from '../../../components/selects/SelectPayeeValue';
import SelectCategoryAndAccount from '../../../components/selects/SelectCategoryAndAccount';
import MyDefaultPageLayout from '../../../components/myDefaultPageLayout/MyDefaultPageLayout';

const formName = 'import-xls-form';

const TransactionImportXls = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [form] = useForm();
  const [formTable] = useForm();

  const inputRef = useRef(null);
  const refCustomModal = useRef(null);

  const [listData, setlistData] = useState([]);
  const [loading, setloading] = useState(false);
  const [saving, setsaving] = useState(false);

  const idAccount = Form.useWatch('idAccount', form);
  const accountName = Form.useWatch('accountName', form);
  const approved = Form.useWatch('approved', form);
  const bankFileType = Form.useWatch('bankFileType', form);

  const handleSaveRow = async (row) => {
    try {
      await formTable.validateFields([[row.key, 'idCategoryAndAccount']]);
      const result = await TransactionActions.saveImportFromXls([row]);
      if (result.success) {
        setlistData((ov) => {
          return ov.map((oo, i) => {
            if (row.key === oo.key) {
              Object.assign(oo, result.data[0]);
              oo.disabled = true;
            }
            return oo;
          });
        });
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    } catch (err) {
      console.log('Error saving row:', err);
    }
  };

  const columns = [
    {
      title: t('WORD_INDEX'),
      key: 'Index',
      width: 50,
      align: 'center',
      onCell: () => ({
        style: { verticalAlign: 'top', paddingTop: 20 },
      }),
      render: (value, record, index) => {
        return <MyText>{index + 1}</MyText>;
      },
    },
    {
      title: t('WORD_DATE'),
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      width: 120,
      align: 'center',
      onCell: () => ({
        style: { verticalAlign: 'top', paddingTop: 20 },
      }),
      render: (value, record) => {
        return <MyText>{UtilDate.formatDate(value)}</MyText>;
      },
    },
    {
      title: t('WORD_ACCOUNT'),
      dataIndex: 'accountName',
      key: 'accountName',
      width: 120,
      onCell: () => ({
        style: { verticalAlign: 'top', paddingTop: 20 },
      }),
      render: (value, record) => {
        return <MyText>{value}</MyText>;
      },
    },
    {
      title: 'Memo',
      dataIndex: 'transactionNote',
      key: 'transactionNote',
      onCell: () => ({
        style: { verticalAlign: 'top' },
      }),
      render: (value) => {
        return <MyText>{value}</MyText>;
      },
    },
    // {
    //   title: 'value',
    //   dataIndex: 'value',
    //   key: 'value',
    //   width: 130,
    // },
    {
      title: 'Inflow',
      dataIndex: 'valueIncome',
      key: 'valueIncome',
      align: 'right',
      width: 130,
      onCell: () => ({
        style: { verticalAlign: 'top', paddingTop: 20 },
      }),
      render: (value, record) => {
        return (
          <MyText>
            {value ? UtilNumber.formatDoubleOrginalValue(value) : ''}
          </MyText>
        );
      },
    },
    {
      title: 'Outflow',
      dataIndex: 'valueExpense',
      key: 'valueExpense',
      align: 'right',
      width: 130,
      onCell: () => ({
        style: { verticalAlign: 'top', paddingTop: 20 },
      }),
      render: (value) => {
        return (
          <MyText>
            {value ? UtilNumber.formatDoubleOrginalValue(value) : ''}
          </MyText>
        );
      },
    },
    {
      title: 'Cleared',
      dataIndex: 'approved',
      key: 'approved',
      width: 15,
      align: 'center',
      render: (value, { disabled, key }) => {
        if (disabled) {
          return Boolean(value) ? null : <HourglassSVG />;
        }
        return (
          <Form.Item>
            <MySwitch
              checked={value}
              disabled={disabled}
              onChange={(ii) => {
                setlistData((ov) => {
                  return ov.map((oo, i) => {
                    if (key === oo.key) {
                      oo.approved = ii;
                    }
                    return oo;
                  });
                });
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'idCategoryAndAccount',
      key: 'idCategoryAndAccount',
      width: 220,
      // onCell: () => ({
      //   style: { verticalAlign: 'middle' },
      // }),
      align: 'center',
      render: (
        _,
        {
          disabled,
          categoryName,
          accountNameTo,
          type,
          key,
          valueIncome,
          idAccount,
        }
      ) => {
        if (disabled) {
          return (
            <MyText>
              {type === TransactionActions.TYPE.TRANSFER
                ? accountNameTo
                : categoryName}
            </MyText>
          );
        }
        return (
          // <SelectCategory
          //   style={{ width: 220 }}
          //   allowClear={true}
          //   name={[key, 'idCategory']}
          //   placeholder="Category"
          //   transactionType={type}
          //   required
          //   onChangeItem={(ii) => {
          //     if (ii) {
          //       setlistData((ov) => {
          //         return ov.map((oo, i) => {
          //           if (i === index) {
          //             oo.idCategory = ii.idCategory;
          //             oo.categoryName = ii.categoryName;
          //           }
          //           return oo;
          //         });
          //       });
          //     }
          //   }}
          // />
          <SelectCategoryAndAccount
            // name={[key, 'idCategory']}
            name={[key, 'idCategoryAndAccount']}
            placeholder="Category"
            allowClear={true}
            style={{ width: 220 }}
            transactionType={
              valueIncome
                ? TransactionActions.TYPE.INCOME
                : TransactionActions.TYPE.EXPENSE
            }
            idAccountToRemove={idAccount}
            onChangeItemAccount={(ii) => {
              console.log('Account :>> ', ii);
              if (ii) {
                setlistData((ov) => {
                  return ov.map((oo) => {
                    if (key === oo.key) {
                      oo.idAccountTo = ii.idAccount;
                      oo.accountNameTo = ii.accountName;
                      oo.idCategoryAndAccount = ii.idKey;
                      oo.idCategory = 0;
                      oo.categoryName = '';
                      oo.idPayee = 0;
                      oo.payeeName = '';
                      oo.type = TransactionActions.TYPE.TRANSFER;
                    }
                    return oo;
                  });
                });
              }
            }}
            onChangeItemCategory={(ii) => {
              console.log('Category :>> ', ii);
              if (ii) {
                setlistData((ov) => {
                  return ov.map((oo, i) => {
                    if (key === oo.key) {
                      oo.idCategory = ii.idCategory;
                      oo.categoryName = ii.categoryName;
                      oo.idCategoryAndAccount = ii.idKey;
                      oo.idAccountTo = 0;
                      oo.accountNameTo = '';
                      oo.type = valueIncome
                        ? TransactionActions.TYPE.INCOME
                        : TransactionActions.TYPE.EXPENSE;
                    }
                    return oo;
                  });
                });
              }
            }}
            required
          />
        );
      },
    },
    {
      title: 'Payee',
      dataIndex: 'idPayee',
      key: 'idPayee',
      width: 180,
      // align: 'top',
      render: (value, { disabled, payeeName, key, type, pendingPayee }) => {
        if (disabled) {
          return <MyText>{payeeName}</MyText>;
        }
        return (
          <Form.Item>
            <SelectPayeeValue
              style={{ width: 180 }}
              // style={{ margin: 0, padding: 0 }}
              placeholder="Payee"
              pendingPayee={pendingPayee}
              disabled={type === TransactionActions.TYPE.TRANSFER}
              value={value}
              onClear={() => {
                setlistData((ov) => {
                  return ov.map((oo, i) => {
                    if (key === oo.key) {
                      oo.idPayee = null;
                      oo.payeeName = '';
                    }
                    return oo;
                  });
                });
              }}
              onChangeItem={(ii) => {
                if (ii) {
                  setlistData((ov) => {
                    return ov.map((oo, i) => {
                      if (key === oo.key) {
                        oo.idPayee = ii.idPayee;
                        oo.payeeName = ii.payeeName;
                      }
                      return oo;
                    });
                  });
                }
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Save',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 60,
      render: (value, row, index) => {
        return (
          <Form.Item>
            <MyButtonSecondary
              loading={saving}
              // label={t('WORD_SAVE')}
              icon={<SaveOutlined />}
              disabled={value}
              onClick={() => handleSaveRow(row)}
            />
          </Form.Item>
        );
      },
    },
  ];

  const handleUploadToServerData = async (values) => {
    const URL_IMPORT_XLS = 'cdb/transactions/importTransactioFromXls';
    await inputRef.current.startUpload(URL_IMPORT_XLS, values);
    setloading(false);
  };

  const onFinish = async (values) => {
    setloading(true);
    if (bankFileType === 'CUSTOM_FILE_XLS') {
      refCustomModal.current.edit(values);
    } else if (bankFileType === 'ACBA_BANK_XLS_FILE') {
      await handleUploadToServerData(values);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSaveAll = async () => {
    try {
      await formTable.validateFields(
        listData
          .filter((oo) => !oo.disabled)
          .map((item) => [item.key, 'idCategoryAndAccount']) // Adjust to your field name
      );
      setsaving(true);
      const result = await TransactionActions.saveImportFromXls(
        listData.filter((oo) => !oo.disabled)
      );
      if (isMounted()) {
        setsaving(false);
        if (result.success) {
          UtilNotify.notifySuccess('WORD_SAVED_SUCCESSFULY');
          goBack();
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    } catch (err) {
      if (err && err.errorFields) {
        console.error('Validation errors:', err.errorFields);
      } else {
        console.error('Error saving idCategories:', err);
      }
    }
  };

  return (
    <MyDefaultPageLayout>
      <MyPageHeader onBack={goBack} title={t('WORD_TRANSACTION_IMPORT_XLS')} />
      <MySpace direction="vertical" size="middle">
        <br />
        {listData?.length ? (
          <MyButtonSecondary
            loading={saving}
            label={t('WORD_SAVE')}
            onClick={handleSaveAll}
          />
        ) : (
          <>
            <FormApp
              name={formName}
              form={form}
              onFinish={onFinish}
              initialValues={{
                bankFileType: 'CUSTOM_FILE_XLS',
                // bankFileType: 'ACBA_BANK_XLS_FILE',
                approved: true,
              }}
            >
              <SelectUploadFileBankDataType
                name="bankFileType"
                label={t('WORD_FILE_TYPE')}
                required
                disabled={loading}
              />
              <UploadFilesManuelStart
                ref={inputRef}
                className="file-xml-upload"
                name="userfile"
                fileTypes={['.xls', '.xlsx']}
                label={t('WORD_FILE')}
                maxCount={1}
                disabled={loading}
                required
                onFail={() => {
                  setloading(false);
                }}
                onChange={(result) => {
                  if (isMounted()) {
                    const r = result.data.map((oo, index) => {
                      return Object.assign({}, oo, {
                        idAccount: idAccount,
                        accountName: accountName,
                        approved: Boolean(approved),
                        key: index,
                      });
                    });
                    setlistData(r);
                    formTable.setFieldsValue(r);
                    setloading(false);
                  }
                }}
              />
              <Space>
                <SelectAccount
                  style={{ width: 200 }}
                  name="idAccount"
                  label={t('WORD_ACCOUNT')}
                  required
                  disabled={loading}
                  onChangeItem={(acc) => {
                    form.setFieldsValue({ accountName: acc.accountName });
                  }}
                />
                <InputTextFormItem hidden name="accountName" />
                <MySwitchFormItem
                  name="approved"
                  label={t('WORD_CLEARED')}
                  disabled={loading}
                />
              </Space>
            </FormApp>
            <MyButtonMain
              loading={loading}
              form={formName}
              label={t('WORD_IMPORT')}
              fullWidth
            />
          </>
        )}
      </MySpace>
      <FormApp component={false} form={formTable}>
        <MyTable
          bordered
          columns={columns}
          dataSource={listData}
          rowKey={(record) => record.key}
          loading={loading}
        />
        <br />
        <br />
      </FormApp>
      <CustomImportModal
        ref={refCustomModal}
        onFinish={async (val) => {
          setloading(true);
          form.setFieldsValue({ ...val });
          await handleUploadToServerData(form.getFieldsValue(true));
        }}
        onClose={() => {
          if (isMounted()) {
            setloading(false);
          }
        }}
      />
    </MyDefaultPageLayout>
  );
};

export default TransactionImportXls;
