import React from 'react';
import MyButton from '../MyButton';
import './MyButtonTransparent.scss';

// import '../MyButton.scss';

const MyButtonTransparent = (props) => {
  const { className } = props;
  return (
    <MyButton
      {...props}
      className={`my-button-transparent ${className}`}
      type="primary"
      ghost
    />
  );
};

export default MyButtonTransparent;
