import { Checkbox, Flex } from 'antd';
import React from 'react';
import colors from '../../../Color.scss';
import UtilNumber from '../../../utils/UtilNumber';

const TableHeaderComponentItem = ({
  filter,
  setfilter,
  type,
  color,
  title,
  value,
}) => {
  const isEnabled = filter?.type?.includes(type);

  const handleChangeFilter = (type, value) => {
    if (value) {
      setfilter((ov) => {
        const newTypeArr = (ov?.type || []).filter((v) => v !== type);

        return { ...ov, type: newTypeArr, page: 0 };
      });
    } else {
      setfilter((ov) => {
        if (ov?.type && Array.isArray(ov.type)) {
          ov.type.push(type);
        } else {
          ov.type = [type];
        }
        return { ...ov, page: 0 };
      });
    }
  };

  return (
    <Flex
      vertical
      gap={8}
      className={`item ${isEnabled ? 'selected' : ''}`}
      style={{
        backgroundColor: isEnabled ? color : colors.whiteColor,
      }}
      onClick={() => {
        handleChangeFilter(type, isEnabled);
      }}
    >
      <Flex gap={7}>
        <Checkbox checked={isEnabled} />
        <span className="title">{title}</span>
      </Flex>
      <span className="amount">{UtilNumber.formatDouble(value || '0')}</span>
    </Flex>
  );
};

export default TableHeaderComponentItem;
