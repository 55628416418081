import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7494 -0.00156983L7.19385 0.0016066L7.20656 6.22382L0.984141 6.23653L0.987318 7.79208L7.20973 7.77938L7.22244 14.0016L8.77799 13.9984L8.76529 7.7762L14.9873 7.76349L14.9841 6.20794L8.76211 6.22065L8.7494 -0.00156983Z"
        fill="white"
      />
    </svg>
  );
};

const SVGPlusIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGPlusIcon);
