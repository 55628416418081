import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
// import './MySelectFormItem.scss';

const MySelectFormItem = (props) => {
  const {
    name,
    label,
    options,
    showSearch,
    onSearch,
    loading,
    hidden,
    disabled,
    required,
    placeholder,
    onChange,
    className,
    allowClear,
    style,
    mode,
  } = props;

  const { t } = useTranslation();

  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${t('WORD_ERROR_PLEASE_SELECT')} ${placeholder ? placeholder : label}!`;

  return (
    <Form.Item
      name={name}
      label={label}
      hasFeedback={false}
      hidden={hidden}
      className={`app-select ${className}`}
      rules={[
        { required: required && !hidden, message: errorMessage },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== '') {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
    >
      <Select
        showSearch={showSearch}
        optionLabelProp="label"
        onSearch={onSearch}
        loading={loading}
        onChange={onChange}
        disabled={disabled}
        onClear={onSearch}
        allowClear={allowClear}
        style={style}
        mode={mode}
        // labelInValue={'label'}
        options={options}
        placeholder={placeholder || label || ''}
      />
      {/* {options?.map((oo, index) => {
          return (
            <Select.Option key={index} value={oo.value} label={oo.label}>
              {oo.label}
            </Select.Option>
          );
        })}
      </Select> */}
    </Form.Item>
  );
};

export default MySelectFormItem;
