import React, { useCallback, useEffect, useState } from 'react';
import MySpace from '../../components/mySpace/MySpace';
import MyTextTitle from '../../components/myText/MyTextTitle';
import { useTranslation } from 'react-i18next';
import MyTextSecondary from '../../components/myText/MyTextSecondary';
import MyDonutChart from '../../components/myDonutChart/MyDonutChart';
import useIsMounted from '@utilityjs/use-is-mounted/useIsMounted';
import UtilObject from '../../utils/UtilObject';
import CategoriesActions from '../../actions/CategoriesActions';
import StatisticsActions from '../../actions/StatisticsActions';
import UtilDate from '../../utils/UtilDate';
import CategoriesDefaultColors, {
  CategoriesDefaultColorsInflow,
} from '../../components/defaultBudgetCategories/CategoriesDefaultColors';
import UtilNotify from '../../utils/UtilNotify';
import UtilNumber from '../../utils/UtilNumber';
import MySegmentedForm from '../../components/mySegmented/MySegmentedForm';
import { Tooltip } from 'antd';
import ArrowSVG from '../../icons/ArrowSVG';
import LoadingIconApp from '../../components/loadingIconApp/LoadingIconApp';
import MyText from '../../components/myText/MyText';
import RedUpArrowSVG from '../../icons/RedUpArrowSVG';
import MyProgressBar from '../../components/myProgressBar/MyProgressBar';
import MyDivider from '../../components/myDivider/MyDivider';
import MyCard from '../../components/myCard/MyCard';

const ActivityChartSection = (props) => {
  const { month, year, title, isIncome } = props;

  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState({});
  const [catNatType, setCatNatType] = useState('Categories');

  const fill = useCallback(async () => {
    setLoading(true);
    let result;
    if (catNatType === 'Nature') {
      result = await StatisticsActions.getScreenDashboardTotalParentNature({
        filter: {
          month: month,
          year: year,
          nature: selected?.nature || 0,
          typeIncomeExpense: isIncome
            ? CategoriesActions.TYPEINCOMEEXPENSE.INCOME
            : CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE,
        },
      });
      if (result.success && UtilObject.isEmpty(selected)) {
        result.data = result.data.map((obj, index) => {
          return Object.assign(obj, {
            categoryName: CategoriesActions.getNatureLabel(obj?.nature),
            categoryColor:
              obj?.categoryColor ||
              (obj.typeIncomeExpense ===
              CategoriesActions.TYPEINCOMEEXPENSE.INCOME
                ? CategoriesDefaultColorsInflow[index]
                : CategoriesDefaultColors[index]),
          });
        });
      }
    } else {
      result = await StatisticsActions.getScreenDashboardTotalParentCategories({
        filter: {
          month: month,
          year: year,
          idParent: selected?.idCategory || 0,
          typeIncomeExpense: isIncome
            ? CategoriesActions.TYPEINCOMEEXPENSE.INCOME
            : CategoriesActions.TYPEINCOMEEXPENSE.EXPENSE,
        },
      });
    }
    if (isMounted()) {
      if (result.success) {
        setList(
          result.data
            .map((obj, index) => {
              obj.categoryColor =
                obj?.categoryColor ||
                (obj.typeIncomeExpense ===
                CategoriesActions.TYPEINCOMEEXPENSE.INCOME
                  ? CategoriesDefaultColorsInflow[index]
                  : CategoriesDefaultColors[index]);
              return obj;
            })
            .filter((oo) => {
              return oo.balance;
            })
        );
        setTotal(result.total);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    }
  }, [month, year, selected, isMounted, catNatType, isIncome]);

  useEffect(() => {
    fill();
  }, [fill]);

  const isSameMonth = UtilDate.getMonth() === '' + month;
  const date = UtilDate.getStartOfMonthDate(month, year);

  return (
    <MyCard>
      <MySpace fullWidth direction="vertical">
        <MySpace fullWidth>
          <MyTextTitle size={17}>{title}</MyTextTitle>
          <MySpace fullWidth>
            <MyTextSecondary
              size={13}
            >{`${UtilDate.getMonthFullNameFormatWithoutYear(
              UtilDate.getThisMonthStart(date)
            )} - ${UtilDate.getMonthFullNameFormat(
              isSameMonth ? UtilDate.getDate() : UtilDate.getThisMonthEnd(date)
            )}`}</MyTextSecondary>
          </MySpace>
        </MySpace>
        <MySegmentedForm
          options={['Categories', 'Nature']}
          onChange={(e) => {
            setSelected({});
            setCatNatType(e);
          }}
          required
        />
        {loading ? (
          <div style={{ height: 300 }}></div>
        ) : (
          <MyDonutChart
            total={total}
            data={list.map((oo) => {
              return {
                value: oo.balance,
                color: oo.categoryColor,
                ...oo,
                label: `${UtilNumber.calcTwoNumbersPercentage(
                  Math.abs(oo.balance),
                  total
                )}%`,
              };
            })}
            onSelect={(e) => {
              if (UtilObject.isEmpty(selected)) {
                setSelected(e);
              }
            }}
          />
        )}
        <MySpace fullWidth direction="vertical">
          <MySpace fullWidth>
            <div
              onClick={() => {
                if (!UtilObject.isEmpty(selected)) {
                  setSelected({});
                }
              }}
              style={{ cursor: !UtilObject.isEmpty(selected) ? 'pointer' : '' }}
            >
              <MyTextSecondary>{t('WORD_ALL_CATEGORIES')}</MyTextSecondary>
            </div>
            {UtilObject.isEmpty(selected) ? null : (
              <MySpace>
                <ArrowSVG color="#a7aab5" />
                <MyTextSecondary bold>{selected.categoryName}</MyTextSecondary>
              </MySpace>
            )}
          </MySpace>
          <MyDivider />
        </MySpace>
        {loading ? (
          <LoadingIconApp />
        ) : (
          list?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  if (UtilObject.isEmpty(selected)) {
                    setSelected(item);
                  }
                }}
              >
                <div
                  // fullWidth
                  // spaceBetween
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: '80%',
                    }}
                  >
                    <MyTextSecondary bold style={{ marginRight: 10 }}>
                      {UtilNumber.calcTwoNumbersPercentage(
                        Math.abs(item?.balance),
                        total
                      )}
                      %
                    </MyTextSecondary>
                    <MyText bold style={{ cursor: 'pointer' }}>
                      {item?.categoryName}
                      {/* Books,apps,games */}
                    </MyText>
                  </div>
                  <MySpace fullWidth style={{ justifyContent: 'end' }}>
                    <MyText bold>
                      {UtilNumber.formatDouble(Math.abs(item?.balance))}
                    </MyText>
                    <MyTextSecondary>{t('WORD_OF')}</MyTextSecondary>
                    <MyText bold>
                      {UtilNumber.formatDouble(item?.budgetValue)}
                    </MyText>
                    <Tooltip
                      title={`You spent ${UtilNumber.formatDouble(
                        Math.abs(item?.balance)
                      )} in ${UtilDate.getMonthName(date, true)} which is

                      ${UtilNumber.calcTwoNumbersPercentageDiff(
                        Math.abs(item?.balance),
                        Math.abs(item?.balanceLast)
                      )}%
                      more than you usually spent by this day of month
                      `}
                    >
                      {Boolean(
                        Math.abs(item?.balance) > Math.abs(item?.balanceLast)
                      ) && (
                        <div style={{ marginTop: 5 }}>
                          <RedUpArrowSVG />
                        </div>
                      )}
                    </Tooltip>
                  </MySpace>
                </div>
                <MyProgressBar
                  color={item?.categoryColor}
                  // shouldBePerc={total.shouldBePerc}
                  totalSpent={Math.abs(item?.balance)}
                  totalBudgeted={item?.budgetValue}
                  percent={UtilNumber.calcTwoNumbersPercentage(
                    Math.abs(item?.balance),
                    total
                  )}
                />
              </div>
            );
          })
        )}
      </MySpace>
    </MyCard>
  );
};

export default ActivityChartSection;
