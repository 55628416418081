import { Button, Dropdown, Flex } from 'antd';
import React from 'react';
import SVGDropdownArrow from '../../icons/SVGDropdownArrow';
import colors from '../../Color.scss';
import { CloseOutlined } from '@ant-design/icons';
import './MyDefaultDropDown.scss';

const MyDefaultDropDown = ({
  menuProps,
  title,
  disableIcon,
  className,
  inactive,
  onClose,
}) => {
  return (
    <React.Fragment>
      <Dropdown menu={menuProps}>
        <Button
          className={`my-button-secondary-filled ${className || ''} ${
            inactive ? 'inactive' : ''
          }`}
          type="primary"
        >
          <Flex gap={10} align="center">
            {title}
            {Boolean(!disableIcon) && (
              <React.Fragment>
                {onClose ? (
                  <CloseOutlined onClick={onClose} />
                ) : (
                  <SVGDropdownArrow
                    style={{
                      pointerEvents: 'none',
                    }}
                    color={inactive ? colors.bluishColor : colors.primaryColor}
                  />
                )}
              </React.Fragment>
            )}
          </Flex>
        </Button>
      </Dropdown>
    </React.Fragment>
  );
};

export default MyDefaultDropDown;
