import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ color, size }) => {
  return (
    <svg
      width={size || 28}
      height={size || 28}
      viewBox="0 0 28 28"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3846 9.76722C26.3846 5.86529 23.1563 2.69232 19.1875 2.69232H8.81366C4.84487 2.69232 1.61536 5.86529 1.61536 9.76722V18.2328C1.61536 22.1336 4.84487 25.3077 8.81366 25.3077H19.1875C23.1563 25.3077 26.3846 22.1336 26.3846 18.2328V9.76722ZM8.81366 4.46164H19.1875C22.1638 4.46164 24.5844 6.84195 24.5844 9.76722V9.78491L24.5807 9.78492V9.79999H20.5153C18.285 9.79999 16.4769 11.6081 16.4769 13.8384C16.4769 16.0688 18.285 17.8769 20.5153 17.8769V17.877H24.5807V17.9025L24.5844 17.9025V18.2328C24.5844 21.1581 22.1638 23.5384 19.1875 23.5384H8.81366C5.83737 23.5384 3.41553 21.1581 3.41553 18.2328V9.76722C3.41553 6.84195 5.83737 4.46164 8.81366 4.46164ZM24.5807 16.0462V11.6308H20.5153C19.2961 11.6308 18.3077 12.6192 18.3077 13.8385C18.3077 15.0577 19.2961 16.0461 20.5153 16.0462V16.0462H24.5807ZM15.3387 8.92986C15.3387 8.44153 14.9355 8.04521 14.4386 8.04521H7.95918C7.46233 8.04521 7.05909 8.44153 7.05909 8.92986C7.05909 9.41819 7.46233 9.81452 7.95918 9.81452H14.4386C14.9355 9.81452 15.3387 9.41819 15.3387 8.92986ZM21.4308 13.8385C21.4308 14.344 21.0209 14.7539 20.5154 14.7539C20.0098 14.7539 19.6 14.344 19.6 13.8385C19.6 13.3329 20.0098 12.9231 20.5154 12.9231C21.0209 12.9231 21.4308 13.3329 21.4308 13.8385Z"
        fill="#A7AAB5"
      />
    </svg>
  );
};

const AccountsOutlineSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(AccountsOutlineSVG);
